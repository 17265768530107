import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { StudyActions} from '../_actions';
import { Study } from '../_models/study';
import { StudyService } from '../_services/study.service';


@Injectable()
export class StudyEffects {

    constructor(private actions$: Actions, private studyService: StudyService) { }

    getStudy$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.GET_STUDY),
        switchMap(({payload}) => this.studyService.getStudy(payload)
            .pipe(
                map((response: Study) => StudyActions.GET_STUDY_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    getStudiesForUser$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.GET_STUDIES),
        switchMap(() => this.studyService.getStudiesForUser()
            .pipe(
                map((response: Study[]) => StudyActions.GET_STUDIES_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    addStudy$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.CREATE_STUDY),
        switchMap(({payload}) => this.studyService.createStudy(payload)
            .pipe(
                map((response: Study[]) => StudyActions.CREATE_STUDY_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    editStudy$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.UPDATE_STUDY),
        switchMap(({payload}) => this.studyService.editStudy(payload)
            .pipe(
                map((response: Study[]) => StudyActions.UPDATE_STUDY_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    addCard$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.ADD_CARD),
        switchMap(({payload}) => this.studyService.addCard(payload)
            .pipe(
                map((response: Study) => StudyActions.ADD_CARD_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    editCard$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.EDIT_CARD),
        switchMap(({payload}) => this.studyService.editCard(payload)
            .pipe(
                map((response: Study) => StudyActions.EDIT_CARD_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    editCards$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.EDIT_CARD_GROUP),
        switchMap(({payload}) => this.studyService.editCards(payload)
            .pipe(
                map((response: Study) => StudyActions.EDIT_CARD_GROUP_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    updateOrder$ = createEffect( () => this.actions$.pipe(
        ofType(StudyActions.UPDATE_CARD_ORDER),
        switchMap(({payload}) => this.studyService.updateCardOrder(payload)
            .pipe(
                map((response: Study) => StudyActions.UPDATE_CARD_ORDER_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

}
