import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from '../_models/properties';
import { Store } from '@ngrx/store';
import { AppState } from '../_reducers';


@Component({
    selector: 'qs-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})


export class FaqComponent  {
    userLoggedIn$: Observable<boolean>;
    urlBase = Properties.frontendUrlBase;

    constructor( private store: Store<AppState>) {
        this.userLoggedIn$ = store.select(state => state.user.loggedIn);
    }
}
