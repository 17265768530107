<main class="qs-app-body">
    <header class="qs-intro-bar">
        <div class="qs-logo-container">
            <img src="{{urlBase}}assets/logo.png" width="50" height="50" alt="Logo">
            <h1>Q-TIP: <u>Q-</u>Method <u>T</u>esting and <u>I</u>nquiry <u>P</u>latform</h1>
        </div>
        <div>
            <button *ngIf="(userLoggedIn$ | async) === true" [routerLink]="['/dashboard']" class="qs-button button-blue">Dashboard</button>
            <button *ngIf="(userLoggedIn$ | async) === false" [routerLink]="['/login']" class="qs-button button-blue">Researcher Login</button>
            <button *ngIf="(userLoggedIn$ | async) === true" [routerLink]="['/']" class="qs-button button-blue" (click)="logout()">Logout</button>
        </div>
    </header>

    <main class="qs-sort-intro-container">
        <section class="qs-section">
            <h3>What is Q Sort?</h3>
            <p>Q-Tip is a free online tool for quantitatively investigating people's qualitative understanding of complex topics.
                It is based on Q-method, a social science research technique in which participants sort different statements about a topic
                by how much they agree with the statement.  Q-TIP enables researchers to define shared viewpoints based on
                subjects' responses to many questions about a single topic.</p>

            <div class="qs-button-container">
                <button [routerLink]="['/login']" class="qs-button button-blue">Start A New Study</button>
            </div>
        </section>

        <section class="qs-section">
            <p style="text-align: center;">Interested to see how Q-TIP works?  Check out our sample sort.</p>
            <div class="qs-button-container">
                <button [routerLink]="['/q-tip']" class="qs-button button-blue">Try Sample Sort</button>
            </div>
        </section>

        <section class="qs-section">
            <h3>Additional Information</h3>
            <p>A collection of useful resources on Q-method can be found on <a href="https://qmethod.org/" target="_blank">Q Methodology</a></p>
            <p>If you would like help or would like more information please view our <a href="javascript:void(0);" [routerLink]="['/faq']">FAQ</a> page.</p>
        </section>
    </main>

    <footer>
        <div class='qs-supporters-container'>
            <img src="{{urlBase}}assets/uwfootertype.png" height='70' alt="University of Wisconsin - Madison Logo">
            <img src="{{urlBase}}assets/IU_Logo.png" height='100' alt="University of Indiana Logo">
            <img src="{{urlBase}}assets/UofG_Logo.png" height='100' alt="University of Guelph Logo">
        </div>

        <div class='qs-copyright-container'>
            <p class='copyright'>&copy; {{ year }} Board of Regents of the <a href="http://www.wisconsin.edu" title = "University of Wisconsin System" >University of Wisconsin System</a></p>
        </div>
    </footer>
</main>
