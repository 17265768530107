import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Properties} from '../_models/properties';
import { Observable } from 'rxjs';
import { AppState } from '../_reducers';
import { LOGOUT_USER } from "../_actions/user.actions";


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    userLoggedIn$: Observable<boolean>;
    urlBase = Properties.frontendUrlBase;
    year: number = 2020;

    constructor( private store: Store<AppState>) {
        this.userLoggedIn$ = store.select(state => state.user.loggedIn);
    }

    ngOnInit() {
        let now = new Date();
        this.year = now.getFullYear();
    }

    logout() {
        this.store.dispatch(LOGOUT_USER());
    }
}
