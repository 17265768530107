export class Card {
    id?: number;
    cardId?: number;
    studyId: number;
    statement: string;
    cardOrder: number;

    constructor(studyId: number, statement: string, order: number, id?: number) {
        this.studyId = studyId;
        this.statement = statement;
        this.cardOrder = order;
        this.id = id;
    }
}


export class CardMovement {
    id?: number;
    cardId: number;
    sessionId: number;
    startColumn: number;
    startSlot: number;
    endColumn: number;
    endSlot: number;
    timestamp?: number;

    constructor(cardId: number, sessionId: number, startColumn: number, startSlot: number, endColumn: number, endSlot: number ) {
        this.cardId = cardId;
        this.sessionId = sessionId;
        this.startColumn = startColumn;
        this.startSlot = startSlot;
        this.endColumn = endColumn;
        this.endSlot = endSlot;
    }
}


export class CardOrder {
    studyId: number;
    order: number[];
}

