export class Properties {
    // public static readonly urlBase: string = 'http://localhost/q-sort/src/api/';
    // public static readonly frontendUrlBase: string = 'http://localhost/q-sort/src/';
    // public static readonly frontendUrl: string = 'http://localhost:4200/#/q-tip/';

    // public static readonly urlBase: string = 'http://localhost/q-test/api/';
    // public static readonly frontendUrlBase: string = 'http://localhost/q-test/';
    // public static readonly frontendUrl: string = 'http://localhost/q-test/#/q-tip/';

    public static readonly urlBase: string = 'https://qtip.geography.wisc.edu/api/';
    public static readonly frontendUrlBase: string = 'https://qtip.geography.wisc.edu/';
    public static readonly frontendUrl: string = 'https://qtip.geography.wisc.edu/#/q-tip/';
}
