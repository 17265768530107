
export class Dialog {
    title?: string;
    message?: string;
    okButton?: boolean;
    yesNoButtons?: boolean;
    cancelButton?: boolean;

    constructor(title: string, message: string, okButton: boolean, yesNoButtons: boolean, cancelButton: boolean) {
        this.title = title;
        this.message = message;
        this.okButton = okButton;
        this.yesNoButtons = yesNoButtons;
        this.cancelButton = cancelButton;
    }
}