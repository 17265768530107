<table datatable [dtOptions]="dtOptions" id="comments-datatable" class="row-border hover">
    <thead>
        <tr>
            <th class="qs-center-cell">Participant</th>
            <th class="qs-center-cell">Card ID</th>
            <th class="qs-center-cell">Comments for study: {{ study }}</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let comment of comments">
            <td class="qs-center-cell" style="width: 200px">{{ comment.session }}</td>
            <td class="qs-center-cell" style="width: 100px">{{ comment.cardId }}</td>
            <td>{{ comment.comment }}</td>
        </tr>
    </tbody>
</table>
