import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Dialog } from './_models/dialog';
import { AppState } from './_reducers';
import { Store } from '@ngrx/store';
import { CLEAR_MODAL_PROPERTIES, SET_MODAL_RESPONSE } from './_actions/layout.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent {
    dialogActive$: Observable<boolean>;
    dialogOptions$: Observable<Dialog>;

    userId: string = '';

    constructor(private store: Store<AppState>) {
        this.dialogActive$ = store.select(state => state.layout.modalActive);
        this.dialogOptions$ = store.select(state => state.layout.modalProperties);
    }


    emitResponse(response: string): void {
        switch (response) {
            case 'ok':
                this.store.dispatch(CLEAR_MODAL_PROPERTIES());
                break;

            case 'cancel':
                this.store.dispatch(CLEAR_MODAL_PROPERTIES());
                break;

            default:
                this.store.dispatch(SET_MODAL_RESPONSE({ payload: response }));
        }
    }

}


