import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Study, Column } from '../_models/study';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Properties} from '../_models/properties';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { AppState } from '../_reducers';
import { CLEAR_SESSIONS } from '../_actions/session.actions';
import { CREATE_STUDY, GET_STUDIES, UPDATE_STUDY } from '../_actions/study.actions';
import { LOGOUT_USER } from '../_actions/user.actions';


@Component({
    selector: 'qs-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./admin.component.scss']
})


export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();

    userLoggedIn$: Observable<boolean>;
    userId$: Observable<number>;
    studies$: Observable<Study[]>;
    unsubscribe$: Subject<void>;
    public urlBase = Properties.frontendUrlBase;

    showStudyOverlay: boolean = false;
    showEditStudyOverlay: boolean = false;
    addStudyForm: FormGroup = new FormGroup({});
    columnOptions = [3,5,7,9,11,13];
    slotOptions = [1,2,3,4,5,6,7,8,9];
    commentOptions = [0,1,2,3,4,5,6,7];
    columnValues = [1,1,1,0,0,0,0,0,0,0,0,0,0];
    showCreateNewAccountForm: boolean = false;
    errorMessage: string = '';
    userId: number = 0;
    studies: Study[] = [];
    dtOptions: any = {};
    editingStudy: Study = null;
    totalSlots: number = 0;


    constructor( private store: Store<AppState>, private formBuilder: FormBuilder, private router: Router) {
        this.userLoggedIn$ = store.select(state => state.user.loggedIn);
        this.studies$ = store.select(state => state.study.studies);
        this.userId$ = store.select(state => state.user.userId);
    }


    ngOnInit(): void {
        this.unsubscribe$ = new Subject<void>();
        this.store.dispatch(CLEAR_SESSIONS());

        let studyColumns = [{ title: 'Created', type: 'date' }, { title: 'Title' }, { title: 'Designation' }, { title: 'Notes' }, { title: 'Actions' }];

        this.dtOptions = {
            pagingType: 'full_numbers',
            columns: studyColumns,
            pageLength: 25,
            dom: 'Bfrtip',
            buttons: ['copy', 'print','excel', 'csv'],
            responsive: true
        };

        this.addStudyForm = this.formBuilder.group({
            studyName: [''],
            numColumns: [3],
            column1:[this.columnValues[0]],
            column2:[this.columnValues[1]],
            column3:[this.columnValues[2]],
            column4:[this.columnValues[3]],
            column5:[this.columnValues[4]],
            column6:[this.columnValues[5]],
            column7:[this.columnValues[6]],
            column8:[this.columnValues[7]],
            column9:[this.columnValues[8]],
            column10:[this.columnValues[9]],
            column11:[this.columnValues[10]],
            column12:[this.columnValues[11]],
            column13:[this.columnValues[12]],
            numberComments:[0],
            notes: [''],
            participantNotes: [''],
            instructions: [''],
            thankYou: [''],
            consent: ['']
        });


        this.studies$.pipe(takeUntil(this.unsubscribe$)).subscribe((studies: Study[]) => {
            this.studies = studies;

            if(this.studies.length > 0) {
                setTimeout( () => {
                    this.dtTrigger.next();
                })
            }

        });

        this.userId$.pipe(takeUntil(this.unsubscribe$)).subscribe((id: number) => {
            if(id > 0) {
                if(this.studies.length === 0) {
                    this.store.dispatch(GET_STUDIES());
                }
                this.userId = id;
            }
        });
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.dtTrigger.unsubscribe();
    }


    logout(): void {
        this.store.dispatch(LOGOUT_USER());
        this.router.navigate(['/']).then( () => {} );
    }


    showCreateStudyForm(): void {
        this.showStudyOverlay = true;
    }


    showEditStudy(study: Study): void {
        this.showEditStudyOverlay = true;
        this.columnValues = [0,0,0,0,0,0,0,0,0,0,0,0,0];
        this.editingStudy = study;

        for(let x = 0; x < study.columns.length; x++) {
            this.columnValues[x] = study.columns[x].slots;
            this.totalSlots += study.columns[x].slots;
        }

        if (this.totalSlots < 7) {
            this.commentOptions = [];

            for(let index = 0; index <= this.totalSlots; index++) {
                this.commentOptions.push(index);
            }
        }

        this.addStudyForm = this.formBuilder.group({
            studyName: [study.title],
            numColumns: [study.columns.length],
            column1:[this.columnValues[0]],
            column2:[this.columnValues[1]],
            column3:[this.columnValues[2]],
            column4:[this.columnValues[3]],
            column5:[this.columnValues[4]],
            column6:[this.columnValues[5]],
            column7:[this.columnValues[6]],
            column8:[this.columnValues[7]],
            column9:[this.columnValues[8]],
            column10:[this.columnValues[9]],
            column11:[this.columnValues[10]],
            column12:[this.columnValues[11]],
            column13:[this.columnValues[12]],
            numberComments:[study.numberOfComments],
            notes: [study.notes],
            participantNotes: [study.notesToParticipants],
            instructions: [study.instructionPrompt],
            thankYou: [study.thankYouMessage],
            consent: [study.consentText]
        });
    }


    saveStudy(): void {
        this.errorMessage = '';
        const studyName = this.createStudyForm['studyName'].value;
        const studyNotes = this.createStudyForm['notes'].value;
        const thankYouMessage = this.createStudyForm['thankYou'].value;
        const consentText = this.createStudyForm['consent'].value;
        let studyColumns: Column[] = [];
        const numberOfComments = this.createStudyForm['numberComments'].value;
        const instructions = this.createStudyForm['instructions'].value;
        const participantNotes = this.createStudyForm['participantNotes'].value;

        if (studyName === '') {
            this.errorMessage = "Study name is required.";
            return;
        }

        let position = 1;
        for(let numberOfSlots of this.columnValues) {
            if(numberOfSlots > 0) {
                studyColumns.push(new Column(position, numberOfSlots));
                position++;
            }
        }

        if(this.studies.length > 0) {
            this.removeTable();
        }

        if(this.editingStudy !== null) {
            const updatedStudy = {
                id: this.editingStudy.id,
                title: studyName,
                ownerId: this.userId,
                columns: studyColumns,
                notes: studyNotes,
                numberOfComments: numberOfComments,
                thankYouMessage: thankYouMessage,
                consentText: consentText,
                notesToParticipants: participantNotes,
                instructionPrompt: instructions
            };
            this.store.dispatch(UPDATE_STUDY({payload: updatedStudy}));
        } else {
            const newStudy = {
                title: studyName,
                ownerId: this.userId,
                columns: studyColumns,
                notes: studyNotes,
                numberOfComments: numberOfComments,
                thankYouMessage: thankYouMessage,
                consentText: consentText,
                notesToParticipants: participantNotes,
                instructionPrompt: instructions
            };

            this.store.dispatch(CREATE_STUDY({payload: newStudy}));
        }

        this.closeOverlays();
    }


    closeOverlays(): void {
        this.showStudyOverlay = false;
        this.showEditStudyOverlay = false;
        this.columnValues = [1,1,1,0,0,0,0,0,0,0,0,0,0];
        this.editingStudy = null;
        this.totalSlots = 0;

        this.addStudyForm = this.formBuilder.group({
            studyName: [''],
            numColumns: [3],
            column1:[this.columnValues[0]],
            column2:[this.columnValues[1]],
            column3:[this.columnValues[2]],
            column4:[this.columnValues[3]],
            column5:[this.columnValues[4]],
            column6:[this.columnValues[5]],
            column7:[this.columnValues[6]],
            column8:[this.columnValues[7]],
            column9:[this.columnValues[8]],
            column10:[this.columnValues[9]],
            column11:[this.columnValues[10]],
            column12:[this.columnValues[11]],
            column13:[this.columnValues[12]],
            numberComments:[1],
            notes: [''],
            thankYou: [''],
            participantNotes: [''],
            instructions: [''],
            consent: ['']
        });
    }


    get createStudyForm() {
        return this.addStudyForm.controls;
    }


    updateSlotView(): void {
        const columnsSelected = this.createStudyForm['numColumns'].value;

        for(let index = 0; index < this.columnValues.length; index++) {
            if (index < columnsSelected && this.columnValues[index] === 0) {
                this.columnValues[index] = 1;
            }

            if (index >= columnsSelected && this.columnValues[index] > 0) {
                this.columnValues[index] = 0
            }
        }

        this.addStudyForm = this.formBuilder.group({
            studyName: [this.createStudyForm['studyName'].value],
            numColumns: [this.createStudyForm['numColumns'].value],
            column1:[this.columnValues[0]],
            column2:[this.columnValues[1]],
            column3:[this.columnValues[2]],
            column4:[this.columnValues[3]],
            column5:[this.columnValues[4]],
            column6:[this.columnValues[5]],
            column7:[this.columnValues[6]],
            column8:[this.columnValues[7]],
            column9:[this.columnValues[8]],
            column10:[this.columnValues[9]],
            column11:[this.columnValues[10]],
            column12:[this.columnValues[11]],
            column13:[this.columnValues[12]],
            numberComments:[this.createStudyForm['numberComments'].value],
            notes: [this.createStudyForm['notes'].value],
            participantNotes: [this.createStudyForm['participantNotes'].value],
            instructions: [this.createStudyForm['instructions'].value],
            thankYou: [this.createStudyForm['thankYou'].value],
            consent: [this.createStudyForm['consent'].value]
        });

    }


    updateSlotCount(column: string) {
        const columnIndex = Number.parseInt(column);
        this.columnValues[columnIndex - 1] = Number.parseInt(this.createStudyForm['column' + column].value);
    }


    removeTable(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
        });
    }
}
