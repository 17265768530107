import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Properties } from '../_models/properties';
import { Observable } from 'rxjs';
import { User, LoginResponse } from '../_models/user';
import { contentHeaders } from '../_models/headers';


@Injectable({ providedIn: 'root' })
export class UserService {
    private static urlBase = Properties.urlBase;

    public static readonly paths = {
        usersPath: 'users',
        loginPath: 'login',
        checkTokenPath: 'check-token',
        lostPasswordPath: 'lost-password',
        sessionsPath: 'session',
        activatePath: 'activate'
    };

    constructor( private http: HttpClient ) { }

    login(email: string, password: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${UserService.urlBase}${UserService.paths.loginPath}`,
            { email: email, password: password },
            contentHeaders)
    }

    addUser(user: User): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${UserService.urlBase}${UserService.paths.usersPath}`,
            { user: user },
            contentHeaders)
    }

    updateUser(user: User): Observable<User> {
        return this.http.put<User>(`${UserService.urlBase}${UserService.paths.usersPath}`,
            { user: user },
            contentHeaders)
    }

    activateUser(userId: number): Observable<User> {
        return this.http.put<User>(`${UserService.urlBase}${UserService.paths.activatePath}`,
            { userId: userId },
            contentHeaders)
    }

    sendPasswordResetLink(email: string): Observable<boolean> {
        return this.http.post<boolean>(`${UserService.urlBase}${UserService.paths.lostPasswordPath}`,
            { email: email },
            contentHeaders)
    }

    checkIfTokenIsValid(token: string): Observable<boolean> {
        const queryParams = new HttpParams().set('token', token);
        return this.http.get<boolean>(`${UserService.urlBase}${UserService.paths.checkTokenPath}`, { params: queryParams });
    }
}
