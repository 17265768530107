import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Study } from '../_models/study';
import { Card, CardOrder } from '../_models/card';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Properties } from '../_models/properties';
import { Session, Comment } from '../_models/session';
import { Dialog } from '../_models/dialog';
import { DataTableDirective } from 'angular-datatables';
import { QueryList } from '@angular/core';
import { AppState } from '../_reducers';
import { EDIT_CARD, EDIT_CARD_GROUP, GET_STUDY, RESET_STUDY, UPDATE_CARD_ORDER } from '../_actions/study.actions';
import { CLEAR_SESSIONS, CREATE_SESSIONS, GET_SESSIONS } from '../_actions/session.actions';
import { LOGOUT_USER } from '../_actions/user.actions';
import { SET_MODAL_PROPERTIES, SHOW_MODAL_OVERLAY } from '../_actions/layout.actions';


@Component({
    selector: 'qs-study-admin',
    templateUrl: './study-admin.component.html',
    styleUrls: ['./admin.component.scss']
})


export class StudyAdminComponent implements OnInit, OnDestroy {
    @ViewChildren(DataTableDirective)
    dtElements: QueryList<DataTableDirective>;
    dtTrigger: Subject<any>[] = [];
    dtOptions: any[] = [];

    routeParameters: Subscription;
    userLoggedIn$: Observable<boolean>;
    userId$: Observable<number>;
    study$: Observable<Study>;
    sessions$: Observable<Session[]>;
    unsubscribe$: Subject<void>;
    public urlBase = Properties.frontendUrlBase;
    public frontendURL = Properties.frontendUrl;

    study: Study = null;
    sessions: Session[] = [];
    showAddSessionForm: boolean = false;
    errorMessage: string = '';
    statements: string[] = [];
    comments: Comment[] = [];
    dataColumns = [ { title: "sample" }];
    columnValues = [];
    cardIds = [];
    sessionRows = [{ designation: '', values: [] }];


    constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router) {
        this.userLoggedIn$ = store.select(state => state.user.loggedIn);
        this.study$ = store.select(state => state.study.study);
        this.userId$ = store.select(state => state.user.userId);
        this.sessions$ = store.select(state => state.session.sessions);
    }


    ngOnInit(): void {
        this.unsubscribe$ = new Subject<void>();

        this.store.dispatch(RESET_STUDY());
        this.store.dispatch(CLEAR_SESSIONS());

        this.dtTrigger['participants'] = new Subject<any>();
        this.dtTrigger['data'] = new Subject<any>();

        this.routeParameters = this.route.params.subscribe(params => {
            if(params['study'] !== null && params['study'] !== undefined) {
                this.store.dispatch(GET_STUDY({payload: params['study']}));
            }
        });


        let participantColumns = [{ title: 'Participant Id' }, { title: 'Cards Sorted' }, { title: 'Session Started', type: 'date' },
            { title: 'Session Ended', type: 'date' }, { title: 'Complete' }, { title: 'URL' }, { title: 'Actions' }];


        this.dtOptions['participants'] = {
            pagingType: 'full_numbers',
            columns: participantColumns,
            pageLength: 25,
            dom: 'Bfrtip',
            buttons: ['copy', 'print','excel', 'csv'],
            responsive: true
        };


        this.study$.pipe(takeUntil(this.unsubscribe$)).subscribe((study: Study) => {
            if(study !== null) {
                this.study = study;
                this.dataColumns = [];
                this.cardIds = [];

                if (this.study.id > 0) {
                    this.statements = [];

                    this.dataColumns.push({ title: "Sessions for (" + this.study.designation + ")" });

                    for(const card of this.study.cards) {
                        if(card.cardId == 0) {
                            this.dataColumns.push({ title: card.id.toString() });
                            this.cardIds.push(card.id);
                        } else {
                            this.dataColumns.push({ title: card.cardId.toString() });
                            // this.cardIds.push(card.cardId);
                            this.cardIds.push(card.id);
                        }
                    }

                    switch (this.study.columns.length) {
                        case 3:
                            this.columnValues = [-1, 0, 1];
                            break;
                        case 5:
                            this.columnValues = [-2, -1, 0, 1, 2];
                            break;
                        case 7:
                            this.columnValues = [-3, -2, -1, 0, 1, 2, 3];
                            break;
                        case 9:
                            this.columnValues = [-4, -3, -2, -1, 0, 1, 2, 3, 4];
                            break;
                        case 11:
                            this.columnValues = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];
                            break;
                        case 13:
                            this.columnValues = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6];
                            break;
                        default:
                    }

                    this.dtOptions['data'] = {
                        pagingType: 'full_numbers',
                        columns: this.dataColumns,
                        pageLength: 25,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'print','excel', 'csv'],
                        responsive: true
                    };

                    for(let card of this.study.cards) {
                        this.statements.push(card.statement);
                    }

                    this.store.dispatch(GET_SESSIONS({payload: study.id}));
                }
            }
        });


        this.userLoggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((isLoggedIn: boolean) => {
            if(isLoggedIn === false) {
                this.router.navigate(['/dashboard']).then( () => {});
            }
        });


        this.sessions$.pipe(takeUntil(this.unsubscribe$)).subscribe((sessions: Session[]) => {

            if(sessions.length > 0) {
                this.sessions = sessions;
                this.sessionRows = [];

                for(const session of this.sessions) {
                    let sessionRow = { designation: session.designation, values: [] };

                    // PREFILL ROW
                    for(let index = 0; index < this.study.cards.length; index++) {
                        sessionRow.values.push(99);
                    }

                    // ADD CARD VALUES
                    for(const column of session.sort.columns) {
                        const columnValue = this.columnValues[column.id - 1];  // push this to session row when index is found

                        for(const card of column.cards) {
                            if(card != 0) {
                                let cardIndex = this.cardIds.indexOf(card);

                                if(cardIndex >= 0) {
                                    sessionRow.values[cardIndex] = columnValue;
                                }
                            }
                        }
                    }

                    this.sessionRows.push(sessionRow);

                    if(session.comments !== null && session.comments !== undefined) {
                        if(session.comments.comments.length > 0) {
                            for(const singleComment of session.comments.comments) {
                                this.comments.push(singleComment);
                            }
                        }
                    }
                }

                setTimeout(() => {
                    this.dtTrigger['participants'].next();
                    this.dtTrigger['data'].next();
                });
            }
        });
    }


    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.dtTrigger['participants'].unsubscribe();
        this.dtTrigger['data'].unsubscribe();
    }


    closeOverlays(): void {
        this.showAddSessionForm = false;
    }


    showAddSessionsForm(): void {
        this.showAddSessionForm = true;
    }


    addSessions(form: NgForm): void {
        this.errorMessage = '';
        let newSessions = form.value.newSessions;

        if (newSessions < 1 || newSessions > 500) {
            this.errorMessage = "Please enter a value between 1 and 500";
            return;
        }

        if(this.sessions.length > 0) {
            this.removeTables();
        }

        this.store.dispatch(CREATE_SESSIONS({study: this.study.id, sessions: newSessions}));
        this.comments = [];
        this.closeOverlays();
    }


    logout(): void {
        this.store.dispatch(LOGOUT_USER());
    }

    saveCardEdits(): void {
        let cards = this.study.cards;
        let editedCards: Card[] = [];

        let index = 0;

        for(const card of cards) {
            if(this.statements[index] == 'This card is blank.' || this.statements[index] === '') {
                const dialogSettings = new Dialog(
                    'Error',
                    'One or more of the cards are blank.',
                    true,
                    false,
                    false);

                this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
                this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
                return;
            }

            const updatedCard = {
                id: card.id,
                studyId: this.study.id,
                statement: this.statements[index],
                cardOrder: card.cardOrder
            }

            editedCards.push(updatedCard)
            index++;
        }

        if(this.sessions.length > 0) {
            this.removeTables();
        }

        this.store.dispatch(EDIT_CARD_GROUP({ payload: editedCards }));

        const dialogSettings = new Dialog(
            '',
            'Card updates have been saved.',
            true,
            false,
            false);

        this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
        this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
    }

    editCard(card: Card, index): void {  // TODO REMOVE WHEN BULK SAVING IS COMPLETE
        this.errorMessage = '';

        if(this.statements[index] === '') {
            const dialogSettings = new Dialog(
                'Error',
                'Statement cannot be blank.',
                true,
                false,
                false);

            this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
            this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
            return;
        }

        const updatedCard: Card = {
            id: card.id,
            studyId: this.study.id,
            statement: this.statements[index],
            cardOrder: card.cardOrder
        };

        if(this.sessions.length > 0) {
          this.removeTables();
        }

        this.store.dispatch(EDIT_CARD({payload: updatedCard}));

        const dialogSettings = new Dialog(
            '',
            'Statement has been saved.',
            true,
            false,
            false);

        this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
        this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
    }


    dragCardStart(event, row) {
        event.dataTransfer.setData('row', row);
    }


    onCardDrop(event, row) {
        const initialRow = event.dataTransfer.getData('row');
        event.preventDefault();

        const draggedCard = this.study.cards.filter(card => (card.cardOrder - 1) === parseInt(initialRow, 10));

        let updatedCards = this.study.cards.filter(card => (card.cardOrder - 1) !== parseInt(initialRow, 10));

        updatedCards.splice(row, 0, draggedCard[0]);

        let cardOrder: number[] = [];

        for(let index = 0; index < this.statements.length; index++) {
            this.statements[index] = '';
        }

        for (const card of updatedCards) {
            cardOrder.push(card.id);
        }

        const updatedOrder: CardOrder = {
            studyId: this.study.id,
            order: cardOrder
        };

        if(this.sessions.length > 0) {
            this.removeTables();
        }

        this.store.dispatch(UPDATE_CARD_ORDER({payload: updatedOrder}));
    }


    allowDrop(event) {
        event.preventDefault();
    }


    copyURL(urlLink) {
        urlLink.select();
        document.execCommand('copy');
        urlLink.setSelectionRange(0,0);

        const dialogSettings = new Dialog(
            '',
            'This URL has been copied to your clipboard.',
            true,
            false,
            false);

        this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
        this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
    }


    removeTables(): void {
        this.dtElements.toArray().forEach( (item) => {

            item.dtInstance.then( (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            })
        });
    }


    clearStudy(): void {
        this.store.dispatch(RESET_STUDY());
    }

}
