import { StudyActions} from '../_actions';
import { Study } from '../_models/study';
import { Action, createReducer, on } from '@ngrx/store';

export interface StudyState {
    study: Study;
    studies: Study[];
}

export const initialState: StudyState = {
    study: null,
    studies: []
};

const studyReducer = createReducer(
    initialState,
    on(StudyActions.GET_STUDY_SUCCESS, (state, {payload}) => ({...state, study: payload })),
    on(StudyActions.GET_STUDIES_SUCCESS, (state, {payload}) => ({...state, studies: payload })),
    on(StudyActions.CREATE_STUDY_SUCCESS, (state, {payload}) => ({...state, studies: payload })),
    on(StudyActions.UPDATE_STUDY_SUCCESS, (state, {payload}) => ({...state, studies: payload })),
    on(StudyActions.ADD_CARD_SUCCESS, (state, {payload}) => ({...state, study: payload })),
    on(StudyActions.EDIT_CARD_SUCCESS, (state, {payload}) => ({...state, study: payload })),
    on(StudyActions.EDIT_CARD_GROUP_SUCCESS, (state, {payload}) => ({...state, study: payload })),
    on(StudyActions.UPDATE_CARD_ORDER_SUCCESS, (state, {payload}) => ({...state, study: payload })),
    on(StudyActions.RESET_STUDY, (state) => ({...state, study: null })),
);

export function reducer(state: StudyState | undefined, action: Action) {
    return studyReducer(state, action);
}
