<div class="qs-app-body">

    <div class="qs-content"></div>

    <div class="qs-overlay-background fadeIn"></div>

    <div *ngIf="hasValidToken === true" class="qs-overlay">
        <div class="qs-overlay-top-nav">
            <a href="javascript:void(0)" (click)="exitPasswordReset()" class="close-link">
                <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
            </a>
        </div>

        <div class="qs-overlay-inner">
            <h3>CHANGE PASSWORD</h3>
            <p class="overlay-info-message">Enter your new password below.</p>
            <form class="qs-narrow-form">
                <p *ngIf="errorMessage !== ''" class="form-error-message"><em>{{ errorMessage }}</em></p>

                <input name="password" type="password" placeholder="Password" [(ngModel)]="passwordText" />
                <input name="password2" type="password" placeholder="Repeat Password" [(ngModel)]="password2Text" />

                <button type="button" class="nav-button-active" (click)="updatePassword()" >Update Password</button><br/>
            </form>
        </div>
    </div>

    <div *ngIf="hasValidToken == false">
        <div class="qs-overlay">
            <div class="qs-overlay-top-nav">
                <a href="javascript:void(0)" (click)="exitPasswordReset()" class="close-link">
                    <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
                </a>
            </div>

            <h5 class="input-error-message">INVALID TOKEN: Please contact the system administrator to reset your account.</h5>
        </div>
    </div>

</div>