export class User {
    id?: number;
    firstName?: string;
    lastName?: string;
    email: string;
    active?: boolean;
    password?: string;

    loggedIn?: boolean;
    token?: string;

    constructor(email: string, active?: boolean, firstName?: string, lastName?: string, password?: string, id?: number) {
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.active = active;
        this.password = password;
        this.id = id;
    }
}

export class LoginResponse {
    token: string;
    error: string;

    constructor(token: string, error: string) {
        this.token = token;
        this.error = error;
    }
}

export interface LoginRequest {
    email: string;
    password: string;
}

export interface TokenHeaders {
    application: string;
    expiration: number;
}






