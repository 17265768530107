import { createAction, props } from '@ngrx/store';
import { User, LoginResponse, LoginRequest } from '../_models/user';

export const LOGIN_USER = createAction('[User] Login User', props<{ payload: LoginRequest }>() );
export const LOGIN_USER_SUCCESS = createAction('[User] Login User Success', props<{ payload: LoginResponse }>() );
export const USER_ROUTE_COMPLETE = createAction('[User] User Route Complete' );

export const LOGOUT_USER = createAction('[User] Logout User');

export const SEND_PASSWORD_RESET = createAction('[User] Send Password Reset', props<{ payload: string }>() );
export const SEND_PASSWORD_RESET_SUCCESS = createAction('[User] Send Password Reset Success', props<{ payload: boolean }>() );

export const ADD_USER = createAction('[User] Add User', props<{ payload: User }>() );
export const ADD_USER_SUCCESS = createAction('[User] Add User Success', props<{ payload: LoginResponse }>() );

export const EDIT_USER = createAction('[User] Edit User', props<{ payload: User }>() );
export const EDIT_USER_SUCCESS = createAction('[User] Edit User Success', props<{ payload: User }>() );

export const ACTIVATE_USER = createAction('[User] Activate User', props<{ payload: number }>() );
export const ACTIVATE_USER_SUCCESS = createAction('[User] Activate User Success', props<{ payload: User }>() );
