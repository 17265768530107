import { SessionActions } from '../_actions';
import { Session } from '../_models/session';
import { CardMovement } from '../_models/card';
import { Action, createReducer, on } from '@ngrx/store';

export interface SessionState {
    session: Session;
    sessions: Session[];
    history: CardMovement[];
}

export const initialState: SessionState = {
    session: null,
    sessions: [],
    history: []
};

const sessionReducer = createReducer(
  initialState,
  on(SessionActions.GET_SESSION_SUCCESS, (state, {payload}) => ({...state, session: payload })),
  on(SessionActions.GET_SESSIONS_SUCCESS, (state, {payload}) => ({...state, sessions: payload })),
  on(SessionActions.CREATE_SESSIONS_SUCCESS, (state, {payload}) => ({...state, sessions: payload })),
  on(SessionActions.UPDATE_SESSION_SUCCESS, (state, {payload}) => ({...state, session: payload })),
  on(SessionActions.RESET_SESSION, (state) => ({...state, session: null })),
  on(SessionActions.ADD_CARD_MOVE_SUCCESS, (state, {payload}) => ({...state, history: payload })),
  on(SessionActions.GET_MOVE_HISTORY_SUCCESS, (state, {payload}) => ({...state, history: payload })),
  on(SessionActions.CLEAR_SESSIONS, (state) => ({...state, sessions: [] })),
);

export function reducer(state: SessionState | undefined, action: Action) {
  return sessionReducer(state, action);
}
