<div class="qs-app-body">

    <div class="qs-overlay-background"></div>

    <div *ngIf="(userLoggedIn$ | async) == false && showCreateNewAccountForm === false && showLostPasswordOverlay === false" class="qs-overlay-narrow">
        <div class="qs-overlay-top-nav">
            <a href="javascript:void(0)" (click)="routeToHome()" class="close-link">
                <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
            </a>
        </div>

        <div class="qs-overlay-inner">
            <h3 style="text-align: center;">Q-TIP RESEARCHER LOGIN</h3>

            <form class="qs-narrow-form">
                <input name="email" type="text" placeholder="Email" [(ngModel)]="emailText" />
                <input name="password" type="password" placeholder="Password" [(ngModel)]="passwordText" />

                <button type="button" class="nav-button-active" (click)="login()">Login</button>
                <p class="qs-form-message">Don't have an account.  Click <a href="javascript:void(0);" (click)="displayNewAccountForm()">here</a> to create a new account.</p>
                <small class="qs-form-message"><a href="javascript:void(0);" (click)="showLostPasswordForm()">Lost your password?</a></small>
            </form>
        </div>
    </div>


    <div *ngIf="showLostPasswordOverlay === true" class="qs-overlay-narrow">
        <div class="qs-overlay-top-nav">
            <a href="javascript:void(0)" (click)="routeToHome()" class="close-link">
                <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
            </a>
        </div>

        <div class="qs-overlay-inner">
            <h3>PASSWORD RESET</h3>

            <form class="qs-narrow-form">
                <p class="overlay-info-message">Enter your email address below and click "Send" to have a reset password link sent to you.</p>
                <p *ngIf="errorMessage !== ''" class="form-error-message"><em>{{ errorMessage }}</em></p>
                <input name="email" type="text" placeholder="Email" [(ngModel)]="emailText" />
                <button type="button" class="nav-button-active" (click)="sendPasswordResetLink()">Send</button>
                <p class="qs-form-message"><a href="javascript:void(0);" (click)="closeOverlays()">Back to login.</a></p>
            </form>
        </div>
    </div>


    <div *ngIf="showCreateNewAccountForm === true" class="qs-overlay">
        <div class="qs-overlay-top-nav">
            <a href="javascript:void(0)" (click)="closeOverlays()" class="close-link">
                <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
            </a>
        </div>

        <div class="qs-overlay-inner">
            <h3 style="text-align: center;">CREATE RESEARCHER ACCOUNT</h3>
            <p class="overlay-info-message">Creating a researcher account allows you to save studies, generate custom urls, and review sort results from the participants of your study.</p>
            <form class="qs-narrow-form">
                <p *ngIf="errorMessage !== ''" class="form-error-message"><em>{{ errorMessage }}</em></p>
                <input name="first-name" type="text" placeholder="First Name" [(ngModel)]="firstNameText" />
                <input name="last-name" type="text" placeholder="Last Name" [(ngModel)]="lastNameText" />
                <input name="email" type="text" placeholder="Email" [(ngModel)]="emailText" />
                <input name="password" type="password" placeholder="Password" [(ngModel)]="passwordText" />
                <input name="password2" type="password" placeholder="Repeat Password" [(ngModel)]="passwordRepeatText" />

                <button type="button" class="nav-button-active" (click)="createAccount()" >Create Account</button><br/>
                <p><a href="javascript:void(0);" (click)="closeOverlays()">Back to login.</a></p>
            </form>
        </div>
    </div>

</div>