import { createAction, props } from '@ngrx/store';
import { Study } from '../_models/study';
import { Card, CardOrder } from '../_models/card';

export const GET_STUDY = createAction('[Study] Get Study', props<{ payload: string }>());
export const GET_STUDY_SUCCESS = createAction('[Study] Get Study Success', props<{ payload: Study }>());

export const GET_STUDIES = createAction('[Study] Get Studies');
export const GET_STUDIES_SUCCESS = createAction('[Study] Get Studies Success', props<{ payload: Study[] }>());

export const CREATE_STUDY = createAction('[Study] Create Study', props<{ payload: Study }>());
export const CREATE_STUDY_SUCCESS = createAction('[Study] Create Study Success', props<{ payload: Study[] }>());

export const UPDATE_STUDY = createAction('[Study] Update Study', props<{ payload: Study }>());
export const UPDATE_STUDY_SUCCESS = createAction('[Study] Update Study Success', props<{ payload: Study[] }>());

export const ADD_CARD = createAction('[Study] Add Card', props<{ payload: Card }>());
export const ADD_CARD_SUCCESS = createAction('[Study] Add Card Success', props<{ payload: Study }>());

export const EDIT_CARD = createAction('[Study] Edit Card', props<{ payload: Card }>());
export const EDIT_CARD_SUCCESS = createAction('[Study] Edit Card Success', props<{ payload: Study }>());

export const EDIT_CARD_GROUP = createAction('[Study] Edit Card Group', props<{ payload: Card[] }>());
export const EDIT_CARD_GROUP_SUCCESS = createAction('[Study] Edit Card Group Success', props<{ payload: Study }>());

export const UPDATE_CARD_ORDER = createAction('[Study] Update Card Order', props<{ payload: CardOrder }>());
export const UPDATE_CARD_ORDER_SUCCESS = createAction('[Study] Update Card Order Success', props<{ payload: Study }>());

export const RESET_STUDY = createAction('[Study] Reset Study');
