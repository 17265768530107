import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { UserActions, LayoutActions } from '../_actions';
import { UserService } from '../_services/user.service';
import { User, LoginResponse } from '../_models/user';
import { Dialog } from '../_models/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../_reducers';

@Injectable()
export class UserEffects {

    constructor(private store: Store<AppState>, private actions$: Actions, private userService: UserService) { }

    loginUser$ = createEffect( () => this.actions$.pipe(
        ofType(UserActions.LOGIN_USER),
        switchMap(({payload}) => this.userService.login(payload.email, payload.password)
            .pipe(
                map((response: LoginResponse) => {

                    if (response.error !== '') {
                        const dialogSettings = new Dialog('Login Error', response.error, true, false, false);
                        this.store.dispatch(LayoutActions.SHOW_ERROR({payload: dialogSettings}));
                    } else {
                        this.store.dispatch(UserActions.LOGIN_USER_SUCCESS({payload: response}));
                    }

                    return UserActions.USER_ROUTE_COMPLETE();
                }),
                catchError(() => EMPTY)
            )
        )
    ));

    addUser$ = createEffect( () => this.actions$.pipe(
        ofType(UserActions.ADD_USER),
        switchMap(({payload}) => this.userService.addUser(payload)
            .pipe(
                map((response: LoginResponse) => {
                    if (response.error && response.error.length) {
                        const dialogSettings = new Dialog('Unable to Add User', response.error, true, false, false);
                        this.store.dispatch(LayoutActions.SHOW_ERROR({payload: dialogSettings}));
                    } else {
                        this.store.dispatch(UserActions.ADD_USER_SUCCESS({payload: response}));
                    }

                    return UserActions.USER_ROUTE_COMPLETE()
                }),
                catchError(() => EMPTY)
            )
        )
    ));

    sendPasswordReset$ = createEffect( () => this.actions$.pipe(
        ofType(UserActions.SEND_PASSWORD_RESET),
        switchMap(({payload}) => this.userService.sendPasswordResetLink(payload)
            .pipe(
                map((response: boolean) => {

                    if (response == true) {
                        const dialogSettings = new Dialog('Reset Password', 'A password reset link has been sent to the email you provided.', true, false, false);
                        this.store.dispatch(LayoutActions.SHOW_ERROR({payload: dialogSettings}));
                    } else {
                        this.store.dispatch(UserActions.SEND_PASSWORD_RESET_SUCCESS({payload: response}));
                    }

                    return UserActions.USER_ROUTE_COMPLETE()
                }),
                catchError(() => EMPTY)
            )
        )
    ));

    editUser$ = createEffect( () => this.actions$.pipe(
        ofType(UserActions.EDIT_USER),
        switchMap(({payload}) => this.userService.updateUser(payload)
            .pipe(
                map((response: User) => UserActions.EDIT_USER_SUCCESS({payload: response})),
                catchError(() => EMPTY)
            )
        )
    ));

    activateUser$ = createEffect( () => this.actions$.pipe(
        ofType(UserActions.ACTIVATE_USER),
        switchMap(({payload}) => this.userService.activateUser(payload)
            .pipe(
                map((response: User) => UserActions.ACTIVATE_USER_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

}
