import { Component, Input, OnInit } from '@angular/core';
import { Properties } from '../_models/properties';
import { Comment } from '../_models/session';


@Component({
    selector: 'qs-comments',
    templateUrl: './comments-table.component.html',
    styleUrls: ['./admin.component.scss']
})


export class CommentsTableComponent implements OnInit {
    @Input() comments: Comment[];
    @Input() study: string = '';

    dtOptions: any = {};

    public urlBase = Properties.frontendUrlBase;

    constructor() {}

    ngOnInit(): void {
        let commentColumns = [{title: 'Participant'}, { title: 'Card ID' }, { title: 'Comments for study: ' + this.study }];

        this.dtOptions = {
            pagingType: 'full_numbers',
            columns: commentColumns,
            pageLength: 25,
            dom: 'Bfrtip',
            buttons: ['copy', 'print','excel', 'csv'],
            responsive: true
        };
    }

}
