import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../_reducers';
import { LOGOUT_USER } from '../_actions/user.actions';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(header => {
            if (header.status === 401) {
                this.store.dispatch(LOGOUT_USER());
                this.router.navigate(['']);
            }

            const error = header.error.message || header.statusText;
            return throwError(error);
        }))
    }
}
