import { Card } from './card';

export interface Study {
    id?: number;
    title: string;
    designation?: string;
    ownerId: number;
    cards?: Card[];
    columns: Column[];
    notes: string;
    numberOfComments: number;
    thankYouMessage: string;
    consentText?: string;
    created?: string;
    notesToParticipants?: string;
    instructionPrompt?: string;
}

export class Column {
    id?: number;
    studyId?: number;
    order: number;
    slots: number;

    constructor(order: number, slots: number) {
        this.order = order;
        this.slots = slots;
    }
}
