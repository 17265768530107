import { Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { QSortComponent } from './q-sort/q-sort.component';
import { DashboardComponent } from './admin/dashboard.component';
import { StudyAdminComponent } from './admin/study-admin.component';
import { FaqComponent } from './faq/faq.component';
import { ChangePasswordComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';


export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'activate/:token', component: LoginComponent},
    { path: 'q-tip', component: QSortComponent },
    { path: 'q-tip/:study', component: QSortComponent },
    { path: 'q-tip/:study/:designation', component: QSortComponent },
    { path: 'q-tip/:view/:study/:designation', component: QSortComponent },
    { path: 'dashboard', component: DashboardComponent},
    { path: 'view-study/:study', component: StudyAdminComponent},
    { path: 'faq', component: FaqComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: '**', redirectTo: '' }
];
