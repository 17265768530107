import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './_reducers';

// COMPONENTS
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { QSortComponent } from './q-sort/q-sort.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './password-reset/password-reset.component';

// ADMIN
import { DashboardComponent } from './admin/dashboard.component';
import { StudyAdminComponent } from './admin/study-admin.component';
import { CommentsTableComponent } from './admin/comments-table.component';

// PIPES
import { TruncatePipe } from './_pipes/ellipsis.pipes';

// INTERCEPTORS
import { JwtInterceptor } from './_helpers/jwt.intercepter';
import { ErrorInterceptor } from './_helpers/error.interceptor';

// SERVICES
import { StudyService } from './_services/study.service';
import { UserService} from './_services/user.service';

// EFFECTS
import { StudyEffects } from './_effects/study.effects';
import { UserEffects } from './_effects/user.effects';
import { SessionEffects } from './_effects/session.effects';

// DATATABLES
import { DataTablesModule } from 'angular-datatables';


@NgModule({
    declarations: [
        AppComponent,
        TruncatePipe,
        HomeComponent,
        QSortComponent,
        DashboardComponent,
        StudyAdminComponent,
        CommentsTableComponent,
        FaqComponent,
        LoginComponent,
        ChangePasswordComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DataTablesModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, { useHash: true }),
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            name: 'NgRx DevTools',
            logOnly: environment.production,
        }),
        EffectsModule.forRoot([StudyEffects, UserEffects, SessionEffects])
    ],
    providers: [
        StudyService,
        UserService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
