import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    transform(text: string, characters: number) {
        if(text.length <= characters) {
            return text;
        }

        return text.substr(0, characters - 1) + '...';
    }
}