import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Properties } from '../_models/properties';
import { Observable } from 'rxjs';
import { Session } from '../_models/session';
import { contentHeaders } from '../_models/headers';
import { CardMovement } from '../_models/card';


@Injectable({ providedIn: 'root' })
export class SessionService {
    private static urlBase = Properties.urlBase;

    public static readonly paths = {
        sessionPath: 'session',
        historyPath: 'history'
    };

    constructor(private http: HttpClient) { }


    getSession(studyDesignation: string, sessionDesignation: string): Observable<Session> {
        const queryParams = new HttpParams().set('study', studyDesignation).set('session', sessionDesignation);
        return this.http.get<Session>(`${SessionService.urlBase}${SessionService.paths.sessionPath}`,{ params: queryParams });
    }


    getSessionsForUser(): Observable<Session[]> {
        return this.http.get<Session[]>(`${SessionService.urlBase}sessions`);
    }


    getSessionsForStudy(studyId: number): Observable<Session[]> {
        const study: string = studyId.toString();

        const queryParams = new HttpParams().set('study', study);
        return this.http.get<Session[]>(`${SessionService.urlBase}${SessionService.paths.sessionPath}`,{ params: queryParams });
    }


    generateSessions(studyId: number, numberOfSessions: number ): Observable<Session[]> {
        return this.http.post<Session[]>(`${SessionService.urlBase}${SessionService.paths.sessionPath}`,
            { study: studyId, numberOfSessions: numberOfSessions },
            contentHeaders)
    }


    updateSession(session: Session): Observable<Session> {
        console.log("UPDATE SESSION: " + JSON.stringify(session));
        return this.http.put<Session>(`${SessionService.urlBase}${SessionService.paths.sessionPath}`,
            { session: session },
            contentHeaders)
    }


    // ==================================   CARD HISTORY  ====================================== //
    addCardMove(cardMove: CardMovement): Observable<CardMovement[]> {
        return this.http.post<CardMovement[]>(`${SessionService.urlBase}${SessionService.paths.historyPath}`,
            { move: cardMove },
            contentHeaders)
    }


    getCardMoveHistory(sessionId: number): Observable<CardMovement[]> {
        const session: string = sessionId.toString();

        const queryParams = new HttpParams().set('session', session);
        return this.http.get<CardMovement[]>(`${SessionService.urlBase}${SessionService.paths.historyPath}`,{ params: queryParams });
    }
}
