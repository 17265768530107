<div class="qs-app-body">
    <a href="javascript:void(0);" [routerLink]="['']" class="home-link"  [ngClass]="(viewOnly) ? 'qs-hidden' : 'none'">
        <img src="{{urlBase}}assets/logo.png" width="50" height="50" alt="Logo" >
        <span class="home-text">Home</span>
    </a>
    <div *ngIf="study" class="qs-upper-container">
        <h3>{{ study.title }}</h3>

        <p *ngIf='study.instructionPrompt != null' class="qs-information-message">{{ study.instructionPrompt }}</p>

        <p *ngIf="showStep2 === false" class="qs-information-message"><strong class="qs-alt-message">Step 1)</strong>
            Click-and-drag to move statements back and forth between the stack and the blanks in the columns below until all
            blanks are occupied.</p>

        <p *ngIf="showStep2 === true && sessionComplete === false" class="qs-information-message slideInLeft" style="padding: 15px 30px 5px 30px;"><strong class="qs-alt-message">Step 2)</strong>
            Please pick {{ study.numberOfComments }} statement<span *ngIf="study.numberOfComments > 1">s</span> that you feel most worth commenting on,
            either because you feel most strongly about <span *ngIf="study.numberOfComments > 1">them</span><span *ngIf="study.numberOfComments ===1">it</span>
            or because <span *ngIf="study.numberOfComments > 1">they were</span><span *ngIf="study.numberOfComments === 1">it was</span> particularly difficult
            to place, and add commentary about your placement of <span *ngIf="study.numberOfComments > 1">them</span><span *ngIf="study.numberOfComments === 1">it
            </span> relative to other statements.  You still have the opportunity to rearrange your cards. Scroll below to see your sort and drag cards
            to the box at the very bottom in order to open space. Click Save & Exit when you are finished providing your reflections and finalizing your sort.</p>

        <div *ngIf="(cardsToSort.length > 0) && (showStep2 === false)" class="qs-card-container">
            <div class="qs-stacked-cards" [ngClass]="'qs-queue-' + cardsToSort.length"
                 (dragover)="allowDrop($event)"
                 (dragenter)="dragEnterHomeCell()"
                 (dragleave)="dragLeaveHome()"
                 (drop)="dragStopHome($event)">
                <div class="qs-card" draggable="true"
                     (dragstart)="dragStartHome($event, cardsToSort[displayedCard])"
                     (dragend)="resetGrid()"
                     [ngClass]="(cardsToSort[displayedCard].id === dragging) ? 'dragging' : ''">
                    <p>{{ cardsToSort[displayedCard].cardOrder}}. {{ cardsToSort[displayedCard].statement }}</p>
                </div>
            </div>

            <div class="qs-card-menu">
                <p *ngIf="cardsToSort.length > 1" class="qs-link" (click)="previousCard()">Previous Card</p>
                <p *ngIf="cardsToSort.length > 1">{{ cardsToSort.length }} Cards</p>
                <p *ngIf="cardsToSort.length === 1" style="width: 100%; text-align: center;">Last Card</p>
                <p *ngIf="cardsToSort.length > 1" class="qs-link" (click)="nextCard()">Next Card</p>
            </div>
        </div>

        <div *ngIf="(cardsToSort.length === 0) && (showStep2 === false)">
            <div class="qs-slot large-slot"
                 (dragover)="allowDrop($event)"
                 (dragenter)="dragEnterHomeCell()"
                 (dragleave)="dragLeaveHome()"
                 (drop)="dragStopHome($event)" >
                <p><em>All cards are sorted.</em></p>
                <p><em>To rearrange cards, drag them here to make space.</em></p>
                <p><em>If you are done please click the "NEXT" below.</em></p>
            </div>

            <div class="qs-card-menu-spacer fadeIn">
                <button class="qs-button button-blue fadeIn" (click)="showStepTwo()" [ngClass]="(viewOnly) ? 'qs-hidden' : 'none'">NEXT</button>
            </div>
        </div>

        <form *ngIf="showStep2 === true" #commentsForm="ngForm" [ngClass]="(sessionComplete === false) ? 'qs-comments-container slideInLeft' : 'qs-comments-container'" (submit)="saveComments(commentsForm);">

            <div *ngIf="sessionComplete === false">
                <div *ngFor="let collection of collectComments" class="qs-comments">
                    <div class="comment-counter">
                        <h2>{{ collection }}</h2>
                    </div>

                    <div class="form-group-stacked">
                        <div class="statement-inner">
                            <label for="card-{{ collection }}">Statement:</label>
                            <select name="card-{{ collection }}" id="card-{{ collection }}" ngModel="0">
                                <option [value]="0">Please Select</option>
                                <option [value]="question.cardId" *ngFor="let question of questionOptions">{{question.questionId}}. {{question.statement | truncate: 60 }}</option>
                            </select>
                        </div>

                        <label for="comment-{{ collection }}">Commentary:</label>
                        <textarea name="comment-{{ collection }}" id="comment-{{ collection }}" rows="2" placeholder="Your commentary" ngModel></textarea>
                    </div>
                </div>
            </div>

            <div *ngIf="sessionComplete === true">
                <div *ngIf="comments.length === 0" class="neutral-container">
                    <h3 class="qs-thank-you-message">Zero statements have been commented on.</h3>
                </div>
                <div *ngFor="let comment of comments; let i = index;" class="qs-comments">
                    <div class="comment-counter">
                        <h2>{{ i + 1 }}</h2>
                    </div>

                    <div class="form-group-stacked">
                        <label>Statement #: <span class="qs-thank-you-message">{{ comment.cardId }}</span></label>
                        <label>Commentary: <span class="qs-thank-you-message">{{ comment.comment }}</span></label>
                    </div>
                </div>
            </div>

            <div class="neutral-outer">
                <div class="comment-counter"></div>

                <div class="neutral-container">
                    <p>Which column below contains the statements that you feel most neutral about?</p>

                    <div class="statement-inner">
                        <label for="neutral">Column:
                            <span *ngIf="sessionComplete === true && neutralColumn > 0" class="qs-thank-you-message">{{ neutralColumn }}</span>
                            <span *ngIf="sessionComplete === true && neutralColumn === 0" class="qs-thank-you-message"> No column selected.</span>
                        </label>
                        <select *ngIf="sessionComplete === false" name="neutral" id="neutral" [(ngModel)]="neutralColumn">
                            <option [value]="0" >Please Select</option>
                            <option *ngFor="let column of study.columns; let x = index;" [value]="x + 1">{{ x + 1 }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="comments-submit">
                <button *ngIf="sessionComplete === false" type="submit" class="qs-button button-blue" [ngClass]="(viewOnly) ? 'qs-hidden' : 'none'">SAVE & EXIT</button>
                <h3 *ngIf="sessionComplete === true" class="qs-thank-you-message fadeIn">THIS Q-SORT IS COMPLETE.</h3>

            </div>
            <div *ngIf="sessionComplete === false">
              <h5 style='color: #20cdfc;'>*Scroll down to view & edit your sort below.</h5>
            </div>
        </form>
    </div>

    <div *ngIf="study" class="qs-color-scale">
        <p>least like how I think</p>
        <p>most like how I think</p>
    </div>

    <div *ngIf="(session !== null && study !== null)" class="qs-lower-container q{{session.sort.columns.length}}">
        <div *ngFor="let column of session.sort.columns; let x = index;" class="qs-column">
            <h5 class="column-header">{{ x + 1 }}</h5>
            <div class="qs-column-inner"
                 (dragover)="allowDrop($event)"
                 (dragenter)="dragOverColumn(x)"
                 (drop)="dropInColumn($event, x)"
                 [ngClass]="(x === overColumn) ? 'over' : ''">


                <div *ngFor="let slot of column.cards; let y = index;" class="qs-slot-wrapper">

                    <div *ngIf="slot === 0" class="qs-slot"></div>

                    <div *ngIf="slot > 0 && sessionComplete === false" class="qs-slot-filled" draggable="true"
                         (dragstart)="dragStartSort($event, slot, x)"
                         (dragend)="resetGrid()"
                         [ngClass]="(slot === dragging) ? 'dragging-sort-card' : ''">
                        <p>{{ getCard(slot).cardOrder }}. {{ getCard(slot).statement }}</p>
                    </div>

                    <div *ngIf="slot > 0 && sessionComplete === true" class="qs-slot-filled" draggable="false">
                        <p>{{ getCard(slot).cardOrder }}. {{ getCard(slot).statement }}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="sessionComplete === false" class="sort-lower-container" style='padding-bottom: 30px;'>
        <div *ngIf="(cardsToSort.length > 0) && (showStep2 === true)" class="qs-card-container">
            <div class="qs-stacked-cards" [ngClass]="'qs-queue-' + cardsToSort.length"
                 (dragover)="allowDrop($event)"
                 (dragenter)="dragEnterHomeCell()"
                 (dragleave)="dragLeaveHome()"
                 (drop)="dragStopHome($event)">
                <div class="qs-card" draggable="true"
                     (dragstart)="dragStartHome($event, cardsToSort[displayedCard])"
                     (dragend)="resetGrid()"
                     [ngClass]="(cardsToSort[displayedCard].id === dragging) ? 'dragging' : ''">
                    <p>{{ cardsToSort[displayedCard].cardOrder}}. {{ cardsToSort[displayedCard].statement }}</p>
                </div>
            </div>

            <div class="qs-card-menu">
                <p *ngIf="cardsToSort.length > 1" class="qs-link" (click)="previousCard()">Previous Card</p>
                <p *ngIf="cardsToSort.length > 1">{{ cardsToSort.length }} Cards</p>
                <p *ngIf="cardsToSort.length === 1" style="width: 100%; text-align: center;">Last Card</p>
                <p *ngIf="cardsToSort.length > 1" class="qs-link" (click)="nextCard()">Next Card</p>
            </div>
        </div>

        <div *ngIf="(cardsToSort.length === 0) && (showStep2 === true)" class="fadeIn">
            <div class="qs-slot large-slot"
                 (dragover)="allowDrop($event)"
                 (dragenter)="dragEnterHomeCell()"
                 (dragleave)="dragLeaveHome()"
                 (drop)="dragStopHome($event)" >
                <p><em>All cards are sorted.</em></p>
                <p><em>To rearrange cards, drag them here to make space.</em></p>
            </div>
        </div>
    </div>

    <ng-container *ngIf='study && study.notesToParticipants != null && study.consentText == null && viewOnly == false && showParticipantNotes === true'>
        <div class="qs-overlay-background fadeIn"></div>

        <div class="qs-overlay-wide">
            <div class="qs-overlay-inner">
                <h3 style="text-align: center; margin-top: 20px;">WELCOME</h3>
                <div class="qs-consent-form">
                    <p style="color:white;">{{ study.notesToParticipants }}</p>
                </div>
                <br />
                <button type="button" class="qs-button button-blue" (click)="closeWelcomeModal()">Close</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showConsentForm === true && viewOnly == false && consentText != null">
        <div class="qs-overlay-background fadeIn"></div>

        <div class="qs-overlay-wide">

            <div class="qs-overlay-inner">
                <div *ngIf='study && study.notesToParticipants != null' class="qs-consent-form">
                    <h3 style="text-align: center; margin-top: 20px;">WELCOME</h3>
                    <p style="color:white;">{{ study.notesToParticipants }}</p>
                </div>

                <h3 style="text-align: center; margin-top: 20px;">Q-TIP STUDY CONSENT</h3>
                <p style="color: #ffe494; text-align: center"><strong>
                    <em>Please read the consent form below and click "Accept & Proceed" below to accept the consent form and continue to the q-sort study.</em></strong></p>
                <br/>
                <div class="qs-consent-form">
                    <p style="color:white;">{{ consentText }}</p>
                </div>
                <br />
                <button type="button" class="qs-button button-blue" (click)="acceptConsent()">ACCEPT & PROCEED</button>
                <button type="button" class="qs-button button-blue" [routerLink]="['/']">Exit</button>
            </div>
        </div>
    </ng-container>

</div>

<div *ngIf="viewOnly === true" class="qs-view-overlay"></div>
