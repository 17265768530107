
export class Session {
    id: number;
    studyId: number;
    designation: string;
    sessionBegin?: string;
    sessionEnd?: string;
    active: boolean;
    sort: Sort;
    userConsent: boolean;
    comments?: Comments;
}

export class Sort {
    totalCards: number;
    cardsSorted: number;
    columns: SortColumn[];
}

export class SortColumn {
    id: number;
    cards: number[];
}

export class Comments {
    comments: Comment[];
    neutralColumn: number;
}

export class Comment {
    cardId: number;
    comment: string;
    session?: string;

    constructor(cardId: number, comment: string, session?: string) {
        this.cardId = cardId;
        this.comment = comment;
        this.session = session;
    }
}
