<div class="qs-app-body">

    <div *ngIf="(userLoggedIn$ | async) == true" class="qs-content" [ngClass]="(showAddSessionForm) ? 'qs-blur' : ''">
        <header class="qs-header-bar">
            <div class="qs-header-logo">
                <a href="javascript:void(0);" [routerLink]="['']" (click)="clearStudy();" class="home-link">
                    <img src="{{urlBase}}assets/logo.png" width="50" height="50" alt="Logo" >
                    <span class="home-text">Home</span>
                </a>
                <h3>Q-TIP Dashboard</h3>
            </div>
            <div class="header-options">
                <button class="qs-button button-blue" (click)="clearStudy();" [routerLink]="['/dashboard']">Back to Studies</button>
                <button class="qs-button button-blue" (click)="logout()">Logout</button>
            </div>
        </header>
    </div>

    <div *ngIf="study !== null" class="qs-content-inner" [ngClass]="(showAddSessionForm) ? 'qs-blur' : ''">

        <section>
            <div>
                <h4><strong class="qs-label">Title: </strong> {{ study.title }}</h4>
                <h4><strong class="qs-label">Notes: </strong>{{ study.notes }}</h4>
                <h4><strong class="qs-label">Statements participant is requested to comment on: </strong>{{ study.numberOfComments }}</h4>
            </div>
        </section>

        <section>
            <div class="qs-table-header-container">
                <h3 class="qs-table-header">STUDY STATEMENT CARDS</h3>
                <button class="qs-button button-blue highlight" (click)='saveCardEdits()'>Save Card Changes</button>
            </div>

            <ng-container *ngIf="study.cards.length > 0">

<!--            <p class="table-info-message">Users will see the statements in this order. Drag and drop the rows to change the order in which users will see them.</p>-->
            <p class="table-info-message">Users will see the statements in this order.</p>
                <table class="qs-dynamic-table">
                    <thead>
                        <tr>
<!--                            <th></th>-->
                            <th class="qs-center-cell">Order Shown</th>
                            <th class="qs-center-cell">Card ID</th>
                            <th class="qs-center-cell">Statement</th>
                        </tr>
                    </thead>

                    <tbody>
<!--                        <tr *ngFor="let card of study.cards; let i = index" draggable="true"-->
<!--                            (dragstart)="dragCardStart($event, i)"-->
<!--                            (drop)="onCardDrop($event, i)"-->
<!--                            (dragover)="allowDrop($event)"  class="qs-dynamic-table-row">-->

                        <tr *ngFor="let card of study.cards; let i = index" class="qs-dynamic-table-row">

<!--                            <td class="drag-cell" style="width: 50px"><img src="{{urlBase}}assets/dragIcon.svg" width="10" height="20" alt="Drag" ></td>-->
<!--                            <td class="drag-cell" style="width: 50px"><img src="{{urlBase}}assets/dragIcon.svg" width="10" height="20" alt="Drag" ></td>-->
                            <td class="qs-center-cell" style="width: 50px">{{ card.cardOrder }}</td>
                            <td *ngIf='card.cardId === 0' class="qs-center-cell" style="width: 50px">{{ card.id }}</td>
                            <td *ngIf='card.cardId > 0' class="qs-center-cell" style="width: 50px">{{ card.cardId }}</td>
                            <td>
                                <input value="{{ card.statement }}" style="width: 100%" [(ngModel)]="statements[i]" />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </ng-container>
        </section>

        <section>
            <div class="qs-table-header-container">
                <h3 class="qs-table-header">Q-SORT PARTICIPANTS</h3>
                <button class="qs-button button-blue highlight" (click)="showAddSessionsForm()">Add Participants</button>
            </div>

            <div>
                <p class="table-info-message">To provide study participants with access to the survey, first add them here. The "Add Participants" button creates unique links which participants can use to complete their own sort. No personal information such as email addresses is stored by Q-TIP.</p>
                <table  datatable [dtOptions]="dtOptions['participants']" [dtTrigger]="dtTrigger['participants']" id="participants-datatable" class="row-border hover">
                    <thead>
                    <tr>
                        <th class="qs-center-cell">Participant Id</th>
                        <th class="qs-center-cell">Cards Sorted</th>
                        <th class="qs-center-cell">Session Started</th>
                        <th class="qs-center-cell">Session Ended</th>
                        <th class="qs-center-cell">Complete</th>
                        <th class="qs-center-cell">URL</th>
                        <th class="qs-center-cell">Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let session of sessions$ | async; let i = index" [ngClass]="((i % 2) === 0) ? 'odd' : 'even'">
                        <td class="qs-center-cell">{{ session.designation }}</td>
                        <td class="qs-center-cell">{{ session.sort.cardsSorted }} of {{ session.sort.totalCards }}</td>
                        <td *ngIf="session.sessionBegin !== '0000-00-00 00:00:00' && session.sessionBegin !== null" class="qs-center-cell">{{ session.sessionBegin | date:'M/d/yy, h:mm a' }}</td>
                        <td *ngIf="session.sessionBegin === '0000-00-00 00:00:00' || session.sessionBegin === null"></td>
                        <td *ngIf="session.sessionEnd !== '0000-00-00 00:00:00' && session.sessionEnd !== null" class="qs-center-cell">{{ session.sessionEnd | date:'M/d/yy, h:mm a' }}</td>
                        <td *ngIf="session.sessionEnd === '0000-00-00 00:00:00' || session.sessionEnd === null"></td>
                        <td *ngIf="session.active === true" class="qs-center-cell">No</td>
                        <td *ngIf="session.active === false" class="qs-center-cell">Yes</td>

                        <td class="qs-center-cell"><input type="text" readonly value="{{frontendURL}}{{study.designation}}/{{session.designation}}" #urlInput class="qs-readonly-input"/></td>

                        <td class="cell-center session-options">
                            <a href="javascript:void(0);" [routerLink]="['/q-tip/view', study.designation, session.designation]" target="_blank">View Session</a>
                            <a href="javascript:void(0);" (click)="copyURL(urlInput)">Copy to Clipboard</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>

        <section>
            <div class="qs-table-header-container">
                <h3 class="qs-table-header">STUDY DATA</h3>
            </div>

            <p class="table-info-message">The numbers in the table below reference the card Id in the Statement Cards above.</p>

            <table datatable [dtOptions]="dtOptions['data']" [dtTrigger]="dtTrigger['data']" id="study-data-datatable" class="row-border hover">
                <thead class="qs-dynamic-header">
                    <tr></tr>
                </thead>

                <tbody>
                <tr *ngFor="let row of sessionRows; let a = index;" [ngClass]="((a % 2) === 0) ? 'odd' : 'even'">
                    <td class="qs-center-cell qs-designation-cell">{{ row.designation }}</td>
                    <td *ngFor="let cell of row.values; let b = index" class="qs-center-cell">
                        <span *ngIf='cell == 99'></span>
                        <span *ngIf='cell != 99'>{{ cell }}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </section>


        <section *ngIf="comments.length > 0">
            <div class="qs-table-header-container">
                <h3 class="qs-table-header">STUDY COMMENTS</h3>
            </div>

            <p class="table-info-message">The numbers in the table below reference the card Id in the Statement Cards above.</p>

            <qs-comments [comments]="comments" [study]="study.designation"></qs-comments>

        </section>
    </div>


    <div *ngIf="showAddSessionForm === true" class="qs-overlay-background fadeIn"></div>

    <div *ngIf="showAddSessionForm === true" class="qs-overlay-narrow">
        <div class="qs-overlay-top-nav">
            <a href="javascript:void(0)" (click)="closeOverlays()" class="close-link">
                <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
            </a>
        </div>

        <div class="qs-overlay-inner">
            <h3 style="text-align: center;">ADD PARTICIPANT SESSIONS</h3>
            <form #addSessionForm="ngForm" class="qs-narrow-form" (submit)="addSessions(addSessionForm)">
                <label for="newSessions" class="add-padding-center">How many new participants would you like to add?</label>
                <small class="error-message">{{errorMessage}}</small>
                <input #newSessions id="newSessions" name="newSessions" type="number" min="1" max="500" ngModel />

                <button type="submit" class="nav-button-active" >Add</button>
            </form>
        </div>
    </div>

</div>
