<router-outlet></router-outlet>

<div *ngIf="(dialogActive$ | async) === true" class="qs-dialog-background"></div>

<div *ngIf="(dialogActive$ | async) === true" class="qs-dialog-box" >

    <div class="qs-overlay-inner">

        <div *ngIf="dialogOptions$ | async; let dialogOptions;" class="dialog-overlay-content-inner">
            <h3>{{ dialogOptions.title }}</h3>
            <p>{{ dialogOptions.message }}</p>

            <div class="qs-dialog-options">
                <button *ngIf="dialogOptions.okButton === true" (click)="emitResponse('ok')" class="qs-button button-blue">OK</button>
                <button *ngIf="dialogOptions.yesNoButtons === true" (click)="emitResponse('yes')" class="qs-button button-blue">YES</button>
                <button *ngIf="dialogOptions.yesNoButtons === true" (click)="emitResponse('no')" class="qs-button button-red">NO</button>
                <button *ngIf="dialogOptions.cancelButton === true" (click)="emitResponse('cancel')" class="qs-button button-red">CANCEL</button>
            </div>
        </div>

    </div>
</div>


