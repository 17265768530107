import { Action, createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../_actions';
import { Dialog } from '../_models/dialog';

export interface LayoutState {
    modalActive: boolean;
    modalProperties: Dialog;
    modalResponse: string;
}

export const initialState: LayoutState = {
    modalActive: false,
    modalProperties: null,
    modalResponse: null
};

const layoutReducer = createReducer(
    initialState,
    on(LayoutActions.SHOW_MODAL_OVERLAY, (state, {payload}) => ({...state, modalActive: payload })),
    on(LayoutActions.SET_MODAL_PROPERTIES, (state, {payload}) => ({...state, modalProperties: payload })),
    on(LayoutActions.SHOW_ERROR, (state, {payload}) => ({...state,modalActive: true, modalProperties: payload })),
    on(LayoutActions.CLEAR_MODAL_PROPERTIES, (state) => ({...state, modalActive: false, modalProperties: null, modalResponse: null })),
    on(LayoutActions.SET_MODAL_RESPONSE, (state, {payload}) => ({...state, modalResponse: payload })),
);

export function reducer(state: LayoutState | undefined, action: Action) {
    return layoutReducer(state, action);
}
