import { UserActions } from '../_actions';
import { TokenHeaders, User } from '../_models/user';
import { Action, createReducer, on } from '@ngrx/store';

export interface UserState {
    user: User;
    userId: number;
    email: string,
    active: boolean,
    loggedIn: boolean,
    token: string,
}

export const initialState: UserState = checkLocalStorageForUserData();

function checkLocalStorageForUserData(): UserState {
    const baseState = {
        user: null,
        userId: 0,
        email: null,
        active: false,
        loggedIn: false,
        token: null,
    };

    const token = localStorage.getItem('qs-token');
    if (!token) { return baseState; }

    const breakdownToken =  token.split('.');
    const parsedHeaders = JSON.parse(base64URLDecode(breakdownToken[0])) as TokenHeaders;
    const parsedPayload = JSON.parse(base64URLDecode(breakdownToken[1])) as User;

    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const tokenExpired = parsedHeaders.expiration <= currentTime;

    if(tokenExpired) {
        return baseState;
    }

    return Object.assign({}, baseState, {
        userId: parsedPayload.id,
        email: parsedPayload.email,
        active: parsedPayload.active,
        loggedIn: true,
        token: token
    });
}

const userReducer = createReducer(
  initialState,
  on(UserActions.LOGIN_USER_SUCCESS, (state, {payload}) => {
    localStorage.setItem('qs-token', payload.token);

    const breakdownToken =  payload.token.split('.');
    const parsedLoginPayload = JSON.parse(base64URLDecode(breakdownToken[1])) as User;

    return {
      ...state,
      userId: parsedLoginPayload.id,
      email: parsedLoginPayload.email,
      active: parsedLoginPayload.active,
      loggedIn: true,
      token: payload.token
    }
  }),
  on(UserActions.LOGOUT_USER, (state) => {
    localStorage.removeItem('qs-token');
    return {
      ...state,
      user: null,
      userId: 0,
      email: null,
      active: false,
      loggedIn: false,
      token: null,
    }
  }),
  on(UserActions.EDIT_USER_SUCCESS, (state, {payload}) => ({...state, userId: payload.id, email: payload.email, active: payload.active })),
  on(UserActions.ACTIVATE_USER_SUCCESS, (state, {payload}) => ({...state, userId: payload.id, email: payload.email, active: payload.active })),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

// https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
export function base64URLDecode (encodedString) {
    return decodeURIComponent(Array.prototype.map.call(atob(encodedString), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
