<div class="qs-app-body">

    <div *ngIf="(userLoggedIn$ | async) == true" class="qs-content" [ngClass]="(showStudyOverlay || showCreateNewAccountForm) ? 'qs-blur' : ''">
        <header class="qs-header-bar">
            <div class="qs-header-logo">
                <a href="javascript:void(0);" [routerLink]="['']" class="home-link">
                    <img src="{{urlBase}}assets/logo.png" width="50" height="50" alt="Logo" >
                    <span class="home-text">Home</span>
                </a>
                <h3>Q-TIP Dashboard</h3>
            </div>
            <div class="header-options">
                <button class="qs-button button-blue" [routerLink]="['/']">Home</button>
                <button class="qs-button button-blue" (click)="logout()">Logout</button>
            </div>
        </header>

        <div class="qs-content-inner">
            <section>
                <div class="qs-table-header-container">
                    <h3 class="qs-table-header">YOUR STUDIES</h3>
                    <button class="qs-button button-blue" (click)="showCreateStudyForm()">Create New Study</button>
                </div>

                <div *ngIf="studies.length > 0">
                    <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="studies-datatable" class="row-border hover">
                        <thead>
                        <tr>
                            <th>Created</th>
                            <th>Title</th>
                            <th>Designation</th>
                            <th>Notes</th>
<!--                            <th class="qs-center-cell">Thank You Message</th>-->
                            <th class="qs-center-cell">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr *ngFor="let study of studies; let i = index" [ngClass]="((i % 2) === 0) ? 'odd' : 'even'">
                            <td>{{ study.created | date:'M/d/yy hh:mm a' }}</td>
                            <td>{{ study.title }}</td>
                            <td>{{ study.designation }}</td>
                            <td>{{ study.notes }}</td>
<!--                            <td>{{ study.thankYouMessage }}</td>-->
                            <td class="cell-center">
                                <a href="javascript:void(0);" [routerLink]="['/view-study', study.designation]" style='margin-right: 15px;'>Study Detail</a>
                                <a href="javascript:void(0);" (click)="showEditStudy(study)">Overview</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>


    <div *ngIf="showStudyOverlay === true || showEditStudyOverlay === true" class="qs-overlay-background fadeIn"></div>


    <div *ngIf="showStudyOverlay === true || showEditStudyOverlay === true" class="qs-overlay">
        <div class="qs-overlay-top-nav">
            <a href="javascript:void(0)" (click)="closeOverlays()" class="close-link">
                <img src="{{urlBase}}/assets/close_circle_blue.svg" width="24" height="24" alt="Close" >
            </a>
        </div>
        <div class="qs-overlay-inner">
            <h3 *ngIf="showStudyOverlay">CREATE STUDY</h3>
            <h3 *ngIf="showEditStudyOverlay">EDIT STUDY</h3>

            <form [formGroup]="addStudyForm" class="qs-narrow-form" (submit)="saveStudy()">
                <p *ngIf="errorMessage !== ''" class="form-error-message"><em>{{ errorMessage }}</em></p>
                <div class="form-group">
                    <label for="studyName">Study Name: </label>
                    <input type="text" formControlName="studyName" class="form-control" id="studyName" />
                </div>

                <div *ngIf="showStudyOverlay" class="form-group-inline">
                    <label for="numColumns">Number of Columns:</label>
                    <select formControlName="numColumns" id="numColumns" (change)="updateSlotView()">
                        <option [value]="column" *ngFor="let column of columnOptions">{{column}}</option>
                    </select>
                </div>

                <div *ngIf="showEditStudyOverlay" class="form-group-inline">
                    <label><strong>Number of Columns: </strong>{{ editingStudy.columns.length }}</label>
                </div>

                <div *ngIf="showEditStudyOverlay" class="form-group-inline">
                    <label><strong>Total Number of Slots: </strong>{{ totalSlots }}</label>
                </div>

                <div *ngIf="showStudyOverlay" class="slots-container">
                    <div *ngIf="createStudyForm['column1'].value > 0" class="form-group-inline">
                        <label>Slots in Column 1:</label>
                        <select formControlName="column1" (change)="updateSlotCount('1')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column2'].value > 0" class="form-group-inline">
                        <label>Slots in Column 2:</label>
                        <select formControlName="column2" (change)="updateSlotCount('2')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column3'].value > 0" class="form-group-inline">
                        <label>Slots in Column 3:</label>
                        <select formControlName="column3" (change)="updateSlotCount('3')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column4'].value > 0" class="form-group-inline">
                        <label>Slots in Column 4:</label>
                        <select formControlName="column4" (change)="updateSlotCount('4')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column5'].value > 0" class="form-group-inline">
                        <label>Slots in Column 5:</label>
                        <select formControlName="column5" (change)="updateSlotCount('5')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column6'].value > 0" class="form-group-inline">
                        <label>Slots in Column 6:</label>
                        <select formControlName="column6" (change)="updateSlotCount('6')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column7'].value > 0" class="form-group-inline">
                        <label>Slots in Column 7:</label>
                        <select formControlName="column7" (change)="updateSlotCount('7')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column8'].value > 0" class="form-group-inline">
                        <label>Slots in Column 8:</label>
                        <select formControlName="column8" (change)="updateSlotCount('8')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column9'].value > 0" class="form-group-inline">
                        <label>Slots in Column 9:</label>
                        <select formControlName="column9" (change)="updateSlotCount('9')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column10'].value > 0" class="form-group-inline">
                        <label>Slots in Column 10:</label>
                        <select formControlName="column10" (change)="updateSlotCount('10')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column11'].value > 0" class="form-group-inline">
                        <label>Slots in Column 11:</label>
                        <select formControlName="column11" (change)="updateSlotCount('11')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column12'].value > 0" class="form-group-inline">
                        <label>Slots in Column 12:</label>
                        <select formControlName="column12" (change)="updateSlotCount('12')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>

                    <div *ngIf="createStudyForm['column13'].value > 0" class="form-group-inline">
                        <label>Slots in Column 13:</label>
                        <select formControlName="column13" (change)="updateSlotCount('13')">
                            <option [value]="slot" *ngFor="let slot of slotOptions">{{slot}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group-inline">
                    <label for="numComments">How many statements would you like participants to comment on?</label>
                    <select formControlName="numberComments" id="numComments">
                        <option [value]="commentNumber" *ngFor="let commentNumber of commentOptions">{{commentNumber}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="notes">Your Study Notes:</label>
                    <textarea formControlName="notes" class="form-control" id="notes" rows="4"
                      placeholder="(Optional) - Add any notes you would like to regarding your sort here.  These notes are only seen by researchers and are not shown on the sort."></textarea>
                </div>

                <div class="form-group">
                    <label for="participant-notes">Notes to participants:</label>
                    <textarea formControlName="participantNotes" class="form-control" id="participant-notes" rows="4"
                      placeholder="(Optional) - Add any explanation or notes you would like the participant to see before they begin to take the survey."></textarea>
                </div>

                <div class="form-group">
                    <label for="instructions">Condition of Instruction:</label>
                    <textarea formControlName="instructions" class="form-control" id="instructions" rows="4"
                              placeholder="(Optional) - Add any additional instructions you wish to provide on the survey here."></textarea>
                </div>

                <div class="form-group">
                    <label for="thank-you-message">Thank You Message:</label>
                    <textarea formControlName="thankYou" class="form-control" id="thank-you-message" rows="2" placeholder="(Optional) - Message to participants after study is completed."></textarea>
                </div>

                <div class="form-group">
                    <label for="consent-text">Consent Form Text:</label>
                    <textarea formControlName="consent" class="form-control" id="consent-text" rows="4" placeholder="(Optional) - Add your consent form text here."></textarea>
                </div>

                <button type="submit" class="nav-button-active" >SAVE</button>
            </form>

        </div>
    </div>

</div>
