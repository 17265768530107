import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SessionActions } from '../_actions';
import { Session } from '../_models/session';
import { CardMovement } from '../_models/card';
import { SessionService } from '../_services/session.service';


@Injectable()
export class SessionEffects {

    constructor(private actions$: Actions, private sessionService: SessionService) { }

    getSessionsForStudy$ = createEffect( () => this.actions$.pipe(
        ofType(SessionActions.GET_SESSIONS),
        switchMap(({payload}) => this.sessionService.getSessionsForStudy(payload)
            .pipe(
                map((response: Session[]) => SessionActions.GET_SESSIONS_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    getSessionsByDesignation$ = createEffect( () => this.actions$.pipe(
        ofType(SessionActions.GET_SESSION),
        switchMap(({study, session}) => this.sessionService.getSession(study, session)
            .pipe(
                map((response: Session) => SessionActions.GET_SESSION_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    getMoveHistory$ = createEffect( () => this.actions$.pipe(
        ofType(SessionActions.GET_MOVE_HISTORY),
        switchMap(({payload}) => this.sessionService.getCardMoveHistory(payload)
            .pipe(
                map((response: CardMovement[]) => SessionActions.GET_MOVE_HISTORY_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    createSessions$ = createEffect( () => this.actions$.pipe(
        ofType(SessionActions.CREATE_SESSIONS),
        switchMap(({study, sessions}) => this.sessionService.generateSessions(study, sessions)
            .pipe(
                map((response: Session[]) => SessionActions.CREATE_SESSIONS_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    addCardMove$ = createEffect( () => this.actions$.pipe(
        ofType(SessionActions.ADD_CARD_MOVE),
        switchMap(({payload}) => this.sessionService.addCardMove(payload)
            .pipe(
                map((response: CardMovement[]) => SessionActions.ADD_CARD_MOVE_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));

    updateSession$ = createEffect( () => this.actions$.pipe(
        ofType(SessionActions.UPDATE_SESSION),
        switchMap(({payload}) => this.sessionService.updateSession(payload)
            .pipe(
                map((response: Session) => SessionActions.UPDATE_SESSION_SUCCESS({ payload: response })),
                catchError(() => EMPTY)
            )
        )
    ));
}
