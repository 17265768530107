
// https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
export class CSTokenUtilities {
    static base64URLDecode(encodedString) {
        return decodeURIComponent(Array.prototype.map.call(atob(encodedString), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    };
}

