import { createAction, props } from '@ngrx/store';
import { Session } from '../_models/session';
import { CardMovement } from '../_models/card';

export const GET_SESSION = createAction('[Session] Get Session', props<{ study: string, session: string }>());
export const GET_SESSION_SUCCESS = createAction('[Session] Get Session Success', props<{ payload: Session }>());

export const GET_SESSIONS = createAction('[Session] Get Sessions', props<{ payload: number }>());
export const GET_SESSIONS_SUCCESS = createAction('[Session] Get Sessions Success', props<{ payload:  Session[] }>());

export const CREATE_SESSIONS = createAction('[Session] Create Sessions', props<{ study: number, sessions: number }>());
export const CREATE_SESSIONS_SUCCESS = createAction('[Session] Create Sessions Success', props<{ payload: Session[] }>());

export const UPDATE_SESSION = createAction('[Session] Update Session', props<{ payload: Session }>());
export const UPDATE_SESSION_SUCCESS = createAction('[Session] Update Session Success', props<{ payload: Session }>());

export const RESET_SESSION = createAction('[Session] Reset Session');

export const ADD_CARD_MOVE = createAction('[Session] Add Card Move', props<{ payload: CardMovement }>());
export const ADD_CARD_MOVE_SUCCESS = createAction('[Session] Add Card Move Success', props<{ payload: CardMovement[] }>());

export const GET_MOVE_HISTORY = createAction('[Session] Get Move History', props<{ payload: number }>());
export const GET_MOVE_HISTORY_SUCCESS = createAction('[Session] Get Move History Success', props<{ payload: CardMovement[] }>());

export const CLEAR_SESSIONS = createAction('[Session] Clear Sessions');
