import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { AppState } from '../_reducers';
import { UserService } from '../_services/user.service';
import { User, LoginResponse } from '../_models/user';
import { CSTokenUtilities } from '../_models/token-utilities';
import { Properties } from '../_models/properties';
import { Dialog } from '../_models/dialog';
import { EDIT_USER, LOGIN_USER_SUCCESS, LOGOUT_USER } from '../_actions/user.actions';
import { SHOW_ERROR } from '../_actions/layout.actions';


@Component({
    selector: 'qs-change-password',
    templateUrl: './password-reset.component.html',
    styleUrls: ['../admin/admin.component.scss']
})


export class ChangePasswordComponent implements OnInit, OnDestroy {
    userService$;
    passwordText: string = '';
    password2Text: string = '';
    hasValidToken: boolean = false;
    errorMessage = '';
    token: string = '';
    urlBase = Properties.frontendUrlBase;

    constructor (private userService: UserService,
                 private route: ActivatedRoute,
                 private router: Router,
                 private store: Store<AppState>) {}


    ngOnInit(): void {
        this.store.dispatch(LOGOUT_USER());

        this.token = this.route.snapshot.queryParamMap.get("token");

        if(this.token !== null || this.token !== undefined) {
            this.confirmToken();
        }
    }


    ngOnDestroy(): void {
        this.userService$.unsubscribe();
    }


    confirmToken(): void {
        this.userService$ = this.userService.checkIfTokenIsValid(this.token).subscribe((response: boolean) => {
            this.hasValidToken = response;
            const loginResponse = new LoginResponse(this.token, '');
            this.store.dispatch(LOGIN_USER_SUCCESS({payload: loginResponse}));
        });
    }


    updatePassword(): void {
        this.errorMessage = '';

        if (this.passwordText.length < 8) {
            this.errorMessage = "Passwords must be at least 8 characters."
        }

        const match = (this.passwordText === this.password2Text);

        if(match === false) {
            this.errorMessage = "Passwords do not match.";
            return
        }

        const breakdownToken =  this.token.split('.');
        const parsedToken = JSON.parse(CSTokenUtilities.base64URLDecode(breakdownToken[1])) as User;

        const editedUser: User = {
            id: parsedToken.id,
            email: parsedToken.email,
            active: parsedToken.active,
            password: this.passwordText
        };

        this.store.dispatch(EDIT_USER({payload: editedUser}));

        const dialogSettings = new Dialog(
            'Reset Password',
            'Your password has been updated.',
            true,
            false,
            false);

        this.store.dispatch(SHOW_ERROR({ payload: dialogSettings }))
        this.router.navigate(['/']);
    }


    exitPasswordReset(): void {
        this.router.navigate(['/']);
    }

}
