import { createAction, props } from '@ngrx/store';
import { Dialog } from '../_models/dialog';

export const SHOW_MODAL_OVERLAY = createAction(
  '[Layout] Show Modal Overlay',
  props<{ payload: boolean }>()
);

export const SET_MODAL_PROPERTIES = createAction(
  '[Layout] Set Modal Properties',
  props<{ payload: Dialog }>()
);

export const CLEAR_MODAL_PROPERTIES = createAction(
  '[Layout] Clear Modal Properties'
);

export const SET_MODAL_RESPONSE = createAction(
  '[Layout] Set Modal Response',
  props<{ payload: any }>()
);

export const SHOW_ERROR = createAction(
  '[Layout] Show Error',
    props<{ payload: Dialog }>()
);
