import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Properties} from '../_models/properties';
import { Observable, Subject, Subscription } from 'rxjs';
import { LoginRequest, LoginResponse, User } from '../_models/user';
import { takeUntil } from 'rxjs/operators';
import { Dialog } from '../_models/dialog';
import { UserService } from '../_services/user.service';
import { AppState } from '../_reducers';
import { ACTIVATE_USER, ADD_USER, LOGIN_USER, LOGIN_USER_SUCCESS, SEND_PASSWORD_RESET } from '../_actions/user.actions';
import { SET_MODAL_PROPERTIES, SHOW_MODAL_OVERLAY } from '../_actions/layout.actions';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit, OnDestroy {
    userLoggedIn$: Observable<boolean>;
    userId$: Observable<number>;
    routeParameters: Subscription;
    userService$;
    unsubscribe$: Subject<void>;

    public urlBase = Properties.frontendUrlBase;
    errorMessage: string = '';

    showCreateNewAccountForm: boolean = false;
    showLostPasswordOverlay: boolean = false;
    firstNameText: string = '';
    lastNameText: string = '';
    emailText: string = '';
    passwordText: string = '';
    passwordRepeatText: string = '';
    token: string = '';
    hasValidToken: boolean = false;
    userId: number = 0;


    constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router, private userService: UserService) {
        this.userLoggedIn$ = store.select(state => state.user.loggedIn);
        this.userId$ = store.select(state => state.user.userId);
        // this.userLoggedIn$ = store.pipe(select(fromRoot.userLoggedIn));
        // this.userId$ = store.pipe(select(fromRoot.getUserId));
    }


    ngOnInit(): void {
        this.unsubscribe$ = new Subject<void>();

        this.routeParameters = this.route.params.subscribe(params => {

            if(params['token'] !== null && params['token'] !== undefined) {
                this.token = params['token'];
                this.confirmToken();
            }
        });


        this.userId$.pipe(takeUntil(this.unsubscribe$)).subscribe((id: number) => this.userId = id);


        this.userLoggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((loggedIn: boolean) => {
            if(loggedIn) {

                if(this.hasValidToken == true && this.userId > 0) {
                    this.store.dispatch(ACTIVATE_USER({payload: this.userId}));
                    // this.store.dispatch(new userActions.ActivateUserAction(this.userId));
                }

                this.router.navigate(['/dashboard']);
            }
        });
    }


    ngOnDestroy(): void {
        this.routeParameters.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    confirmToken(): void {
        this.userService$ = this.userService.checkIfTokenIsValid(this.token).subscribe((response: boolean) => {
            if(response == true) {

                this.hasValidToken = true;

                const dialogSettings = new Dialog(
                    'Thank you',
                    'You account has been activated.',
                    true,
                    false,
                    false);

                this.store.dispatch(SET_MODAL_PROPERTIES({ payload: dialogSettings}));
                this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true }));

                const loginResponse = new LoginResponse(this.token, '');
                this.store.dispatch(LOGIN_USER_SUCCESS({payload: loginResponse}));
            }
        });
    }


    login(): void {
        if (this.emailText === '' || this.passwordText === '' ) { return; }

        const loginRequest: LoginRequest = { email: this.emailText, password: this.passwordText }
        this.store.dispatch(LOGIN_USER({payload: loginRequest }));

        this.emailText = '';
        this.passwordText = '';
    }


    showLostPasswordForm(): void {
        this.showLostPasswordOverlay = true;
        this.emailText = '';
    }


    displayNewAccountForm(): void {
        this.showCreateNewAccountForm = true;
        this.showLostPasswordOverlay = false;
    }


    closeOverlays(): void {
        this.showLostPasswordOverlay = false;
        this.showCreateNewAccountForm = false;
        this.emailText = '';
        this.errorMessage = '';
        this.firstNameText = '';
        this.lastNameText = '';
        this.passwordText = '';
        this.passwordRepeatText = '';
    }


    sendPasswordResetLink() {
        this.errorMessage = '';

        if (this.emailText === '') {
            this.errorMessage = "Email cannot be blank.";
            return;
        }

        let emailValidation = /.+@.+\..+/i;
        let validEmail = emailValidation.test(this.emailText);

        if (!validEmail) {
            this.errorMessage = "Invalid Email Address";
            return;
        }

        this.store.dispatch(SEND_PASSWORD_RESET({payload: this.emailText}));

        this.emailText = '';
        this.showLostPasswordOverlay = false;
    }


    routeToHome() {
        this.router.navigate(['/']);
    }


    validateEmail(email: string) {
        let emailValidation = /.+@.+\..+/i;
        return emailValidation.test(email);
    }


    createAccount(): void {
        if (this.firstNameText === '') {
            this.errorMessage = "Please enter your first name.";
            return;
        }

        if (this.lastNameText === '') {
            this.errorMessage = "Please enter your last name";
            return;
        }

        const validEmail = this.validateEmail(this.emailText);

        if (!validEmail) {
            this.errorMessage = "Invalid Email Address";
            return;
        }

        if ( this.passwordText.length < 8) {
            this.errorMessage = "Passwords must be at least 8 characters in length.";
            return;
        }

        if ( this.passwordRepeatText === '' ||  (this.passwordText !== this.passwordRepeatText) ) {

            this.errorMessage = "Passwords do not match.";
            return;
        }

        this.errorMessage = '';
        this.showCreateNewAccountForm = false;
        const newUser = new User(this.emailText, null, this.firstNameText, this.lastNameText, this.passwordText);
        this.store.dispatch(ADD_USER({payload: newUser}));

        this.firstNameText = '';
        this.lastNameText = '';
        this.emailText = '';
        this.passwordText = '';
        this.passwordRepeatText = '';

        const dialogSettings = new Dialog(
            'New Account',
            'Please check your email for an email from q-tip which contains a link to activate your account.',
            true,
            false,
            false);

        this.store.dispatch(SET_MODAL_PROPERTIES({ payload: dialogSettings}));
        this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true }));
        this.routeToHome();
    }

}
