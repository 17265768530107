<div class="qs-app-body">
    <header class="qs-intro-bar">
        <div class="qs-logo-container">
            <img src="{{urlBase}}assets/logo.png" width="50" height="50" alt="Logo">
            <h1>Q-TIP: <u>Q-</u>Method <u>T</u>esting and <u>I</u>nquiry <u>P</u>latform</h1>
        </div>

        <div>
            <button [routerLink]="['/']" class="qs-button button-blue">Home</button>
            <button *ngIf="(userLoggedIn$ | async) === true" [routerLink]="['/dashboard']" class="qs-button button-blue">Dashboard</button>
            <button *ngIf="(userLoggedIn$ | async) === false" [routerLink]="['/login']" class="qs-button button-blue">Login</button>
        </div>
    </header>

    <div class="qs-sort-intro-container">
        <h3>Frequently Asked Questions</h3>

        <div class="qs-section">
            <p>Q-TIP is a free online tool for quantitatively investigating people’s qualitative understanding of complex topics.
                It is an application of Q-method, a social science survey technique in which participants sort different statements
                about a topic by how much they agree with the statement. Q-method enables researchers to define shared viewpoints
                based on subjects’ responses. While Q-method surveys are traditionally administered in person and on paper,
                it is not always practical to do so.  <strong>Q-TIP allows researchers to administer Q-method surveys on-line and to
                    export the data for statistical analysis.</strong></p>

            <p>
                Q-TIP was commissioned by Morgan Robertson (Professor, University of Wisconsin-Madison), Eric Nost (Assistant Professor,
                University of Guelph) and Rebecca Lave (Associate Professor, Indiana University), and built by UW-Madison DoIT.
            </p>

            <div class='qs-supporters-container' style='margin-top: 25px;'>
                <img src="{{urlBase}}assets/uwfootertype.png" height='70' alt="University of Wisconsin - Madison Logo">
                <img src="{{urlBase}}assets/IU_Logo.png" height='100' alt="University of Indiana Logo">
                <img src="{{urlBase}}assets/UofG_Logo.png" height='100' alt="University of Guelph Logo">
            </div>
        </div>

        <div class="qs-section">
            <h4>What is a Q-method survey?</h4>
            <div class="qs-answer">
                <p>A Q-method survey consists of a set of statements that participants sort into an array of columns, demonstrating the
                    way they think about a defined topic. This set of statements, called a “concourse”, is created to reflect a range of
                    possible viewpoints on the topic of research. For example, a Q-method survey on patriotism in the US might include statements such as:
                </p>

                <ul>
                    <li>“The bald eagle is an important symbol of American national identity,”</li>
                    <li>“It should not be illegal to burn an American flag,”</li>
                    <li>“Learning songs such as ‘America the Beautiful’ should be a mandatory part of elementary school education in the US.”</li>
                </ul>

                <p>
                    Unlike a standard survey where each question can be answered independently of the other, Q-method forces participants to rank
                    the statements relative to each other.  By placing statements on a spectrum from “most like how I think” to “least like how I think”,
                    participants reveal their own beliefs about a topic. The aim of Q-method is to observe how suites of beliefs may define subject-positions
                    shared by different participants who create similar orderings, rather than (as in traditional survey methods) to understand how single
                    characteristics of participants may be distributed across a population. This is useful in defining similarities and differences between
                    seemingly different participants in debates over an issue (e.g. potential common ground between foresters and environmentalists in a
                    conservation project).
                </p>

                <p>
                    Q-TIP allows researchers to configure Q-sorts for any number of participants, to generate a small or large number of statements, and to
                    specify different numbers of columns to sort statements into. A collection of useful resources on Q-method exists
                    <a href="https://qmethod.org/resources/how2q/" target="_blank">here</a>.
                </p>
            </div>
        </div>

        <div class="qs-section">
            <h4>Is Q-TIP secure?</h4>
            <div class="qs-answer">
                <p>Q-TIP is designed to protect user data and maintain a high degree of confidentiality.</p>

                <ul>
                    <li>Each Q-TIP researcher has their own secure login, and not even the Q-TIP Administrator can access data from their account. </li>
                    <li>Participants do not enter ANY personal data in taking the survey. Rather, when a researcher conducts the survey, each participant
                        is sent their own unique URL, at which they will be presented with a consent page and complete the survey. <strong>Researchers must keep
                            track of which URL is sent to which participant; this is the only way to link responses with specific participants.</strong> This allows
                        for the safeguarding of participants’ identities during the research, because their identity and their responses are not stored together. </li>
                </ul>
            </div>
        </div>


        <div class="qs-section">
            <h4>How do I collect results?</h4>
            <div class="qs-answer">
                <p>Q-TIP allows two kinds of data to be collected:</p>

                <ol>
                    <li>The order in which the participant laid out the statements</li>
                    <li>Any comments they added to their sort, including the column containing the statements they felt most neutral about.</li>
                </ol>

                <p>On the configuration page where researchers design the survey, results can be printed or downloaded in Excel (.xlsx) or comma-separated values (.csv) spreadsheet formats.</p>
                <ul>
                    <li>The “Study Data” section indicates the sorting of each statement by each participant.</li>
                    <li>The “Study Comments” section collects the notes that participants made at the end of their sort about which statements were particularly provoking.</li>
                </ul>
            </div>
        </div>


        <div class="qs-section">
            <h4>How do I analyze the results?</h4>
            <div class="qs-answer">
                <p>Q-method results are often analyzed using Principal Components Analysis, but Q-TIP does not provide an analysis tool.
                   You should first download your participants’ layouts from the dashboard - click Excel or CSV under "Study Data" in
                   the sort view. Several statistics software packages designed specifically for Q-method are freely available on the web, including
                    <a href='https://shawnbanasick.github.io/ken-q-analysis-beta/index.html#section1' target='_blank'>Ken-Q</a>,
                    <a href='http://schmolck.org/qmethod/' target='_blank'>PQMethod</a>,
                   and the <a href='https://cran.r-project.org/web/packages/qmethod/index.html' target='_blank'>qmethod</a> package for R. Q-TIP stores sort results in a format that is friendly
                   to these tools.</p>
            </div>
        </div>


        <div class="qs-section">
            <h4>Can I tailor the comments section?</h4>
            <div class="qs-answer">
                <p>The comments section is not configurable at this time.</p>
            </div>
        </div>


        <div class="qs-section">
            <h4>Which browsers is Q-TIP currently compatible with?</h4>
            <div class="qs-answer">
                <p>Q-TIP is optimized for desktop versions of Google Chrome and Mozilla Firefox and is also compatible with Safari and
                    Internet Explorer 9.0+. Q-TIP is currently not available on mobile devices.</p>
            </div>
        </div>


        <div class="qs-section">
            <h4>How do I delete a participant or sort?</h4>
            <div class="qs-answer">
                <p>To prevent both accidental and unauthorized data loss, deleting data is not allowed through the application.</p>
            </div>
        </div>


        <div class="qs-section">
            <h4>Something else isn't working. What do I do?</h4>
            <div class="qs-answer">
                <p>Unfortunately, we are unable to provide personalized help at this time. However, if you have found bugs in the program,
                    please let us know and we will attempt to resolve the problem.</p>
            </div>
        </div>


        <div class="qs-section">
            <h4>How do I contact you?</h4>
            <div class="qs-answer">
                <p>You can email <a href="mailto:mmrobertson@wisc.org">mmrobertson@wisc.org</a></p>
            </div>
        </div>


        <div class="qs-section">
            <h4>Step-by-step instructions for <em>creating</em> your own Q-method survey using Q-TIP</h4>
            <div class="qs-answer">
                <ol>
                    <li>If you do not already have one, create an account by clicking “Researcher Login” on the Q-TIP homepage. You will
                        then have an opportunity to provide your account details and will receive email confirmation.</li>

                    <li>Once you have confirmed your account, you can login using the “Researcher Login” button on the Q-TIP homepage. This will take you to your “Dashboard.”</li>

                    <li>Create a new study by using the “Create New Study” button in the upper right corner of the “Dashboard.”</li>

                    <li>You will then be asked to specify the properties of the study.
                        <ul>
                            <li>Use a unique and memorable name for your survey</li>
                            <li>You may select an odd number of columns for your sort, ranging between 3 and 13.</li>
                            <li>For each column, you are able to set the number of slots available for participants to place statements.
                                This should reflect the number of statements in your concourse. For instance, if you create a study with 5 columns,
                                and the following number of “rows” in each – 1 2 3 2 1 – you will want to develop 9 statements to sort
                                (this kind of bell curve or normal distribution is often employed in Q-method studies, but is not required.
                                That is, you could create a study with 5 columns and the following distribution of statements – 1 2 2 2 2).
                            </li>

                            <li>You are able to add notes to yourself about the study – participants will NOT be able to see these.</li>
                            <li>You can tailor a thank you message to be displayed when participants complete their sort.</li>
                            <li>Finally, you are able to post a consent statement that will be shown to participants when they open their unique
                                URL and before they are able to complete their sort.</li>
                        </ul>
                    </li>

                    <li>The new study is then placed in the list of “Your Studies” on the “Dashboard.”
                        <ul>
                            <li>You may edit your notes, consent statement, and thank you message at any time by clicking “Edit” under “Actions.”
                                You are NOT able to edit the number of columns and slots.</li>
                        </ul>
                    </li>

                    <li>To add statements and participants to your study, click “View” under “Actions.”</li>

                    <li>
                        Under “Study Statements” you are able to specify the “cards” that participants will see when they complete their sort.
                        <ul>
                            <li>Each card should contain one statement.</li>
                            <li>Each card receives its own unique ID that will allow you to identify it in participants’ sorts when you export the study results.</li>
                            <li>You can adjust the order in which statements will be shown to participants by dragging and dropping the rows.</li>
                            <li>Be sure to save any edits you make to statements by clicking “Save” under “Actions.”</li>
                        </ul>
                    </li>

                    <li>
                        Under “Q-Sort Participants” you can generate URLs to send by clicking “Add Participants.” Q-TIP will automatically generate a random and
                        unique URL to a unique sort for each new participant you choose to add.
                    </li>

                    <li>
                        It is the researcher’s responsibility to pair the randomly generated “Participant ID” to email addresses and other personal
                        information pertinent to their study.
                        <ul>
                            <li>
                            You may download an Excel or CSV version of your “Participant IDs” so that you can locally and securely
                            match these IDs with personally-identifying information such as names and email addresses.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Only someone with the unique URL will be able to access that sort. Be sure to inform participants that they should not share their link.
                    </li>

                    <li>
                        You are now able to email the link to your participant. You must do this using your own email application.
                    </li>

                    <li>
                        You will be able to download results at any time using the “Study Data” and “Study Comments” sections.
                    </li>

                </ol>
            </div>
        </div>

        <div class="qs-section">
            <h4>Step-by-step instructions for <em>participating</em> in a survey on Q-TIP</h4>
            <div class="qs-answer">
                <ol>
                    <li>
                        Q-TIP generates a random URL for each participant, and researchers send each participant their unique link.
                        This means you do NOT need to register for / log into Q-TIP to complete your sort.
                    </li>

                    <li>
                        As a participant, when you open your unique link, at the top of the page you will see the "stack" of
                        statements the researcher is asking you to sort. You will also see empty spaces in pre-set columns below.
                        Your task is to click each statement and drag it to a spot.
                    </li>

                    <li>
                        Which spot? The columns are laid out on an axis from "least like how I think" to "most like how I think.”
                    </li>

                    <li>
                        You can move statement cards between columns as long as there is space in the target column.
                    </li>

                    <li>
                        If the target column is full, you can move the statement card back to the stack and open up space in the
                        desired column by moving its statement cards around.
                    </li>

                    <li>
                        After you've placed all the statement cards from "least like I think" to "most like I think", you will
                        be asked to indicate which column of statements you feel the most neutral about (this may or may not be
                        the middle column). You may also be asked to provide written reflections on some of the statements you sorted,
                        depending on how the researcher has configured the study.
                    </li>

                    <li>
                        When you are finished, you can click "Save and Exit" and safely close your browser window. Your input
                        (but no identifying information) will be recorded in a secure database accessible only to the researcher.
                    </li>
                </ol>
            </div>

        </div>

    </div>

</div>
