import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Properties } from '../_models/properties';
import { Observable } from 'rxjs';
import { Study } from '../_models/study';
import { Card, CardOrder } from '../_models/card';
import { contentHeaders } from '../_models/headers';


@Injectable({ providedIn: 'root' })
export class StudyService {
    private static urlBase = Properties.urlBase;

    public static readonly paths = {
        studyPath: 'study',
        cardsPath: 'cards',
        cardOrderPath: 'card-order'
    };

    constructor(private http: HttpClient) { }

    getStudy(study: string): Observable<Study> {
        const queryParams = new HttpParams().set('study', study);
        return this.http.get<Study>(`${StudyService.urlBase}${StudyService.paths.studyPath}`,{ params: queryParams });
    }

    getStudiesForUser(): Observable<Study[]> {
        return this.http.get<Study[]>(`${StudyService.urlBase}studies`);
    }

    createStudy(study: Study): Observable<Study[]> {
        return this.http.post<Study[]>(`${StudyService.urlBase}${StudyService.paths.studyPath}`,
            { study: study }, contentHeaders)
    }

    editStudy(study: Study): Observable<Study[]> {
        return this.http.put<Study[]>(`${StudyService.urlBase}${StudyService.paths.studyPath}`,
            { study: study }, contentHeaders)
    }

    // --------------------    CARDS   -----------------------------
    addCard(card: Card): Observable<Study> {
        return this.http.post<Study>(`${ StudyService.urlBase }${ StudyService.paths.cardsPath }`,
            { card: card }, contentHeaders)
    }

    editCard(card: Card): Observable<Study> {
        return this.http.put<Study>(`${ StudyService.urlBase }${ StudyService.paths.cardsPath }`,
            { card: card }, contentHeaders)
    }

    editCards(cards: Card[]): Observable<Study> {
        return this.http.put<Study>(`${ StudyService.urlBase }${ StudyService.paths.cardsPath }`,
            { cards: cards }, contentHeaders)
    }

    updateCardOrder(newCardOrder: CardOrder): Observable<Study> {
        return this.http.put<Study>(`${ StudyService.urlBase }${ StudyService.paths.cardOrderPath }`,
            { order: newCardOrder}, contentHeaders)

    }
}
