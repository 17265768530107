import { ActionReducerMap } from '@ngrx/store';

import * as fromUser from './user.reducer';
import * as fromLayout from './layout.reducer';
import * as fromStudy from './study.reducer';
import * as fromSession from './session.reducer';

export interface AppState {
    user: fromUser.UserState;
    session: fromSession.SessionState;
    study: fromStudy.StudyState;
    layout: fromLayout.LayoutState;
}

export const reducers: ActionReducerMap<AppState> = {
    user: fromUser.reducer,
    session: fromSession.reducer,
    study: fromStudy.reducer,
    layout: fromLayout.reducer
};
