import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../_reducers';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Study } from '../_models/study';
import { Card } from '../_models/card';
import { Session, SortColumn, Comments, Comment } from '../_models/session';
import { takeUntil } from 'rxjs/operators';
import { Properties } from '../_models/properties';
import { Dialog } from '../_models/dialog';
import { NgForm } from '@angular/forms';
import { GET_STUDY, RESET_STUDY } from "../_actions/study.actions";
import { GET_SESSION, RESET_SESSION, UPDATE_SESSION, UPDATE_SESSION_SUCCESS } from '../_actions/session.actions';
import { SET_MODAL_PROPERTIES, SHOW_MODAL_OVERLAY } from '../_actions/layout.actions';


@Component({
    selector: 'qs-q-sort',
    templateUrl: './q-sort.component.html',
    styleUrls: ['./q-sort.component.scss']
})


export class QSortComponent implements OnInit, OnDestroy {
    routeParameters: Subscription;
    unsubscribe$: Subject<void>;
    study$: Observable<Study>;
    session$: Observable<Session>;

    study: Study = null;
    session: Session = null;
    sample: boolean = false;
    cardsToSort: Card[] = [];
    displayedCard: number = 0;
    dragging: number = -1;
    overColumn: number = -1;
    startColumn: number = -1;
    showStep2: boolean = false;
    comments: Comment[] = [];
    collectComments: number[] = [];
    neutralColumn: number = 0;
    sessionComplete: boolean = false;
    questionOptions: { questionId: number, statement: string, cardId: number } [] = [];
    viewOnly: boolean = false;
    urlBase = Properties.frontendUrlBase;

    consentText = '';
    showConsentForm: boolean = false;
    showParticipantNotes: boolean = true;


    constructor( private store: Store<AppState>, private route: ActivatedRoute, private router: Router) {
        this.study$ = store.select(state => state.study.study);
        this.session$ = store.select( state => state.session.session);
    }


    ngOnInit() {
        this.unsubscribe$ = new Subject<void>();

        this.routeParameters = this.route.params.subscribe(params => {
            this.store.dispatch(RESET_STUDY());

            if(params['view'] !== null && params['view'] !== undefined) {
                this.viewOnly = true;
            }

            if(params['study'] !== null && params['study'] !== undefined) {
                this.store.dispatch(GET_STUDY({payload: params['study']}));

                if(params['designation'] !== null && params['designation'] !== undefined) {
                    this.store.dispatch(GET_SESSION({ study: params['study'], session: params['designation'] }));
                }
            } else {
                this.store.dispatch(GET_STUDY({payload: 'sample'}));
                this.store.dispatch(GET_SESSION({study: 'sample', session: 'sample'}));
                this.sample = true;
            }
        });


        this.study$.pipe(takeUntil(this.unsubscribe$)).subscribe((study: Study) => {
            if(study !== null) {
                this.study = study;
                this.consentText = study.consentText;

                if(this.consentText === '' || this.consentText == null) {
                    this.showConsentForm = false;
                }

                for(let index = 1; index <= this.study.numberOfComments; index++) {
                    this.collectComments.push(index);
                }

                if (this.study.cards.length > 0) {
                    for (const card of this.study.cards) {
                        this.cardsToSort.push(new Card(study.id, card.statement, card.cardOrder, card.id));
                        this.questionOptions.push({ questionId: card.cardOrder, statement: card.statement, cardId: card.id  });
                    }
                }

                if(this.session !== null) {
                    this.setSort();
                }
            }
        });

        this.session$.pipe(takeUntil(this.unsubscribe$)).subscribe( (session: Session) => {
            if(session !== null) {
                this.session = session;

                if(this.study !== null) {
                    this.setSort();

                }
            }
        });
    }


    ngOnDestroy(): void {
        this.routeParameters.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


    setSort(): void {
        // AUTOMATICALLY SET CONSENT IF NO CONSENT TEXT
        if(this.session.userConsent === false && (this.consentText === '' || this.consentText == null)) {
            this.acceptConsent();
            return;
        }

        this.showConsentForm = !this.session.userConsent;

        if(this.cardsToSort.length > 0) {

            for(const column of this.session.sort.columns) {
                for(const cardId of column.cards) {
                    if(cardId > 0) {
                        this.cardsToSort = this.cardsToSort.filter((card: Card) => {
                            return card.id !== cardId;
                        });
                    }
                }
            }

            if(this.cardsToSort.length === 0) {
                if(this.session.comments !== null) {
                    this.comments = [];
                    for(let comment of this.session.comments.comments) {
                        const card = comment.cardId.toString();
                        this.comments.push(new Comment(Number.parseInt(card), comment.comment));
                    }

                    if(this.session.comments.neutralColumn !== null) {
                        const neutral = this.session.comments.neutralColumn.toString();
                        this.neutralColumn = Number.parseInt(neutral);
                    }
                }

                if(this.session.active === false) {
                    this.sessionComplete = true;
                }

                this.showStepTwo();
            }

            if(this.session.active === false ) {
                this.sessionComplete = false;
            }
        } else if(this.session.active === false) {

            if(this.session.comments !== null) {
                this.comments = [];
                for(let comment of this.session.comments.comments) {
                    const card = comment.cardId.toString();
                    this.comments.push(new Comment(Number.parseInt(card), comment.comment));
                }

                if(this.session.comments.neutralColumn !== null) {
                    const neutral = this.session.comments.neutralColumn.toString();
                    this.neutralColumn = Number.parseInt(neutral);
                }
            }

            this.sessionComplete = true;
            this.showStepTwo();
        }
    }


    previousCard(): void {
        const numberOfCards = this.cardsToSort.length;
        this.displayedCard = (this.displayedCard !== 0) ? this.displayedCard - 1 : numberOfCards - 1;
    }


    nextCard(): void {
        const numberOfCards = this.cardsToSort.length;
        this.displayedCard = (this.displayedCard < numberOfCards - 1) ? this.displayedCard + 1 : 0;
    }


    allowDrop(event) {
        event.preventDefault();
    }


    dragOverColumn(column: number) {
        this.overColumn = column;
    }


    dropInColumn(event, column: number): void {
        event.preventDefault();

        if (this.startColumn === column || this.session.userConsent === false ) { return }

        const card = this.getCard(this.dragging);

        let sortColumn: SortColumn = this.session.sort.columns[column];
        let updatedCards = [];
        let slotAvailable = false;
        let cardsSorted = this.session.sort.cardsSorted;

        // UPDATE DESTINATION COLUMN
        for(const cardId of sortColumn.cards) {
            if (cardId > 0 || slotAvailable === true) {
                updatedCards.push(cardId);
            }

            if(cardId === 0 && slotAvailable === false) {
                slotAvailable = true;
                updatedCards.push(card.id)
            }
        }


        if (slotAvailable === true) {
            const updatedSortColumn: SortColumn = { id: sortColumn.id, cards: updatedCards };

            let unchangedColumns = this.session.sort.columns.filter( (sortColumn: SortColumn) => {
                return sortColumn.id !== updatedSortColumn.id;
            });

            let updatedSortColumns: SortColumn[] = [...unchangedColumns, updatedSortColumn];

            if(this.startColumn >= 0) {
                // REMOVE CARD FROM ORIGIN COLUMN
                let originColumn: SortColumn = this.session.sort.columns[this.startColumn];
                updatedCards = [];

                for(const cardId of originColumn.cards) {
                    if (cardId === this.dragging) {
                        updatedCards.push(0);
                    } else {
                        updatedCards.unshift(cardId);
                    }
                }

                const updatedOriginColumn: SortColumn = { id: originColumn.id, cards: updatedCards };

                unchangedColumns = updatedSortColumns.filter( (sortColumn: SortColumn) => {
                    return sortColumn.id !== updatedOriginColumn.id;
                });

                updatedSortColumns = [...unchangedColumns, updatedOriginColumn];

            } else {
                cardsSorted++;
            }

            updatedSortColumns.sort(function (a, b) {
                if (a.id > b.id ) { return 1; }
                if (a.id < b.id) { return -1; }
                return 0;
            });


            const updatedSession = {
                id: this.session.id,
                studyId: this.session.studyId,
                designation: this.session.designation,
                sort: { totalCards: this.session.sort.totalCards, cardsSorted: cardsSorted, columns: updatedSortColumns },
                active: true,
                userConsent: this.session.userConsent,
                comments: this.session.comments
            };

            if(this.sample === false && this.viewOnly === false) {
                this.store.dispatch(UPDATE_SESSION({payload: updatedSession}));
            } else {
                this.store.dispatch(UPDATE_SESSION_SUCCESS({payload: updatedSession}));
            }

            this.resetGrid();

        } else {
            const dialogSettings = new Dialog(
                'Information',
                'This column is full. You can move cards already sorted back to the deck to free up space.',
                true,
                false,
                false);

            this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
            this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
            this.resetGrid();
        }
    }


    dragStartHome(event, card: Card): void {
        event.dataTransfer.setData('card', card.id.toString());
        this.dragging = card.id;
        this.startColumn = -1;
    }


    dragStartSort(event, id: number, column: number): void {
        event.dataTransfer.setData('card', id.toString());
        this.dragging = id;
        this.startColumn = column;
    }


    dragEnterHomeCell(): void {
        this.overColumn = -1;
    }


    dragLeaveHome(): void {
        this.overColumn = -1;
    }


    dragStopHome(event): void {
        event.preventDefault();

        if (this.startColumn === -1 ) { return }

        const card = this.getCard(this.dragging);

        this.cardsToSort.unshift(card);

        // REMOVE CARD FROM ORIGIN COLUMN
        let originColumn: SortColumn = this.session.sort.columns[this.startColumn];
        let updatedCards = [];
        let cardsSorted = this.session.sort.cardsSorted;

        for(const cardId of originColumn.cards) {
            if (cardId === this.dragging) {
                updatedCards.push(0);
            } else {
                updatedCards.unshift(cardId);
            }
        }

        const updatedOriginColumn: SortColumn = { id: originColumn.id, cards: updatedCards };

        let unchangedColumns = this.session.sort.columns.filter( (sortColumn: SortColumn) => {
            return sortColumn.id !== updatedOriginColumn.id;
        });

        let updatedColumns = [...unchangedColumns, updatedOriginColumn];

        cardsSorted--;

        updatedColumns.sort(function (a, b) {
            if (a.id > b.id ) { return 1; }
            if (a.id < b.id) { return -1; }
            return 0;
        });


        const updatedSession = {
            id: this.session.id,
            studyId: this.session.studyId,
            designation: this.session.designation,
            sort: { totalCards: this.session.sort.totalCards, cardsSorted: cardsSorted, columns: updatedColumns },
            active: true,
            userConsent: this.session.userConsent,
            comments: this.session.comments
        };

        if(this.sample === false && this.viewOnly === false) {
            this.store.dispatch(UPDATE_SESSION({payload: updatedSession}));
        } else {
            this.store.dispatch(UPDATE_SESSION_SUCCESS({payload: updatedSession}))
        }

        this.resetGrid();
    }


    getCard(id: number): Card {
        const cardArray = this.study.cards.filter( (card: Card) => {
            return card.id === id;
        });

        return cardArray[0];
    }


    resetGrid(): void {
        this.dragging = -1;
        this.overColumn = -1;
    }


    showStepTwo(): void {
        this.resetGrid();
        this.showStep2 = true;
    }


    saveComments(form: NgForm): void {
        const neutral = form.value.neutral;

        for(let index = 1; index <= this.study.numberOfComments; index++) {
            const cardId = form.value['card-' + index];
            const commentary = form.value['comment-' + index];
            const participantId = this.session.designation;

            if(cardId > 0) {
                const newComment = new Comment(cardId, commentary, participantId);
                this.comments.push(newComment);
            }
        }

        const updatedComments: Comments = {
            comments: this.comments,
            neutralColumn: neutral
        };

        const updatedSession = {
            id: this.session.id,
            studyId: this.session.studyId,
            designation: this.session.designation,
            sort: this.session.sort,
            active: false,
            userConsent: this.session.userConsent,
            comments: updatedComments
        };

        if(this.sample === false && this.viewOnly === false) {
            this.store.dispatch(UPDATE_SESSION({payload: updatedSession}));

            const dialogSettings = new Dialog(
                'Thank you.',
                this.study.thankYouMessage,
                true,
                false,
                false);

            this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
            this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
        } else {
            this.store.dispatch(RESET_STUDY());
            this.store.dispatch(RESET_SESSION());

            const dialogSettings = new Dialog(
                'Thank you.',
                'Your q-sort is complete.',
                true,
                false,
                false);

            this.store.dispatch(SET_MODAL_PROPERTIES({payload: dialogSettings}));
            this.store.dispatch(SHOW_MODAL_OVERLAY({payload: true}));
        }

        this.router.navigate(['/']);
    }


    acceptConsent(): void {
        const updatedSession = {
            id: this.session.id,
            studyId: this.session.studyId,
            designation: this.session.designation,
            sort: this.session.sort,
            active: this.session.active,
            userConsent: true,
            comments: this.session.comments
        };

        this.showParticipantNotes = false;
        this.store.dispatch(UPDATE_SESSION({payload: updatedSession}));
    }


    closeWelcomeModal(): void {
        this.showParticipantNotes = false;
    }
}
